import React from 'react'
import './PopupLoader.scss'
export default class PopupLoader extends React.Component<any, any>{

    public render(): React.ReactElement<any> {
        return (
            <div className='loader-bg'>
                <div className='loader-container'>
                    <div className="loading-animation">
                        <div className="loading-holder">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}