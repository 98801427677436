import React from 'react'
import './TreeViewTable.scss'
import { Global } from '../../services/Global';
import { MSGraphService } from '../../services/MSGraphService';
import DataLoader from '../DataLoader/DataLoader';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import PopupLoader from '../PopupLoader/PopupLoader';
import GroupUserPicker from '../GroupUserPicker/GroupUserPicker';
export default class TreeViewTable extends React.Component<any, any>{
    private contentSkipToken: string = "";

    public constructor(props: any, state: any) {
        super(props)
        this.state = {
            childFolderAlignment: "0 0 0" + (this.props.parentLevel * 15) + " px",
            contents: this.props.data,
            folderStatusMessage: "",
            showInhertConfirmation: false,
            inhertConfirmationMessage: "",
            selectedFolderInfo: null,
            isInhertStatusUpdateProcessing: false,
            showRemoveAccessConfirmation: false,
            removeAccessConfirmationMessage: "",
            selectedAccessUserIndex: null,
            isProcessingRemoveAccess: false
        }
    }
    componentDidMount(): void {

    }

    componentWillUnmount(): void {

    }

    private onCheckInheritStatus(folder: any, folderIndex: number) {
        console.log("checkInheritStatus");
        MSGraphService.checkInheritStatus({ teamId: this.props.team.id, channelId: folder.id, membershipType: folder.membershipType, driveInfo: folder.driveInfo, folderInfo: folder.spFolderInfo }).then((resData: any) => {
            if (resData.status == "success") {
                this.state.contents[folderIndex].isInheritStatusLoader = false;
                this.state.contents[folderIndex].isInherit = resData.data;
                this.setState({ contents: this.state.contents })
            } else {
                if (folder.inheritStatusCheckCount < 5) {
                    folder.inheritStatusCheckCount++
                    this.onCheckInheritStatus(folder, folderIndex);
                }
            }
        }).catch((error: any) => {
            if (folder.inheritStatusCheckCount < 5) {
                folder.inheritStatusCheckCount++
                this.onCheckInheritStatus(folder, folderIndex);
            }
        })
    }

    private onSelectFolder(folder: any, index: number) {
        if (folder.childFolderCount == 0) return;
        this.state.contents[index].isOpened = (this.state.contents[index].isOpened == undefined) ? true : !this.state.contents[index].isOpened;
        if (this.state.contents[index].contents != undefined && this.state.contents[index].contents.length > 0) {
            this.setState({ contents: this.state.contents })
            return;
        }
        this.state.contents[index].isLoading = true
        this.setState({ contents: this.state.contents })

        MSGraphService.getTeamFolderContents({ teamId: this.props.team.id, driveInfo: folder.driveInfo, spFolderInfo: folder.spFolderInfo }).then((resData) => {
            this.state.contents[index].isLoading = false;
            if (resData.status === "success") {
                this.state.contents[index].contents = []
                resData.data.forEach((currentFolder: any, CFindex: number) => {
                    currentFolder.isInheritStatusLoader = false;
                    currentFolder.inheritStatusCheckCount = 0
                    this.state.contents[index].contents.push(currentFolder);
                    this.setState({ contents: this.state.contents })
                    if (currentFolder.isInheritApplicable && !currentFolder.isInheritStatusUpdated) {
                        this.onCheckInheritStatus(currentFolder, CFindex)
                    }
                });
            }
        }).catch((error) => {
            this.contentSkipToken = "";
            this.state.contents[index].isLoading = false;
            this.setState({ contents: this.state.contents })
        })
    }

    private onClickInhert(selectedCheckBox: any, folder: any, index: number) {
        let correntSelFolder = JSON.parse(JSON.stringify(folder));
        correntSelFolder.index = index;
        this.setState({ selectedFolderInfo: correntSelFolder });
        this.onShowInhertConfirmation(true, folder.isInherit);
    }

    private onShowInhertConfirmation(status: boolean, isInherit: any = null) {
        if (isInherit == null) {
            this.setState({ showInhertConfirmation: status, inhertConfirmationMessage: "" })
        } else {
            this.setState({ showInhertConfirmation: status, inhertConfirmationMessage: (isInherit) ? "Are you sure to break the parent inheritance?" : "You are about to inherit permissions from the parent folder or document library. Any custom permissions will be lost." })
        }
    }

    private onUpdateInhertStatus() {
        this.setState({ isInhertStatusUpdateProcessing: true });
        this.onShowInhertConfirmation(false);
        MSGraphService.updateInheritanceStatus({ inheritance: !this.state.selectedFolderInfo.isInherit, folderInfo: this.state.selectedFolderInfo.spFolderInfo }).then((resInsStat: any) => {
            if (resInsStat.status == "success") {
                this.state.contents[this.state.selectedFolderInfo.index].isInherit = !this.state.selectedFolderInfo.isInherit;
                this.setState({ contents: this.state.contents, isInhertStatusUpdateProcessing: false })
                toast.success("Inherit Success", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                this.state.contents[this.state.selectedFolderInfo.index].isMembersLoading = true;
                this.state.contents[this.state.selectedFolderInfo.index].members = [];
                this.setState({ contents: this.state.contents })
                MSGraphService.getAccessedList({ folderInfo: this.state.selectedFolderInfo.driveInfo }).then((resDataAccessList: any) => {
                    if (resDataAccessList.status == "success") {
                        this.state.contents[this.state.selectedFolderInfo.index].isMembersLoading = false;
                        this.state.contents[this.state.selectedFolderInfo.index].members = resDataAccessList.data;
                        this.setState({ contents: this.state.contents, selectedFolderInfo: null })
                    } else {
                        this.state.contents[this.state.selectedFolderInfo.index].accessListStatusMessage = "Try again";
                        this.state.contents[this.state.selectedFolderInfo.index].isMembersLoading = false;
                        this.setState({ contents: this.state.contents })
                    }
                }).catch((errorAccessList: any) => {
                    this.state.contents[this.state.selectedFolderInfo.index].accessListStatusMessage = "Try again";
                    this.state.contents[this.state.selectedFolderInfo.index].isMembersLoading = false;
                    this.setState({ contents: this.state.contents })
                })
            } else {
                toast.error("Inherit Failed", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                this.setState({ isInhertStatusUpdateProcessing: false });
            }
        }).catch((errorInsStat) => {
            toast.error("Inherit Failed", {
                autoClose: 1000,
                hideProgressBar: true
            })
            this.setState({ isInhertStatusUpdateProcessing: false });
        })
    }

    private onShowRemoveAccessConfirmation(status: boolean, folder: any = null, userIndex: any = null) {
        if (folder == null) {
            if (!status) this.setState({ showRemoveAccessConfirmation: status })
        } else {
            if (folder.isInheritApplicable && !folder.isInherit) this.setState({ selectedFolderInfo: folder, showRemoveAccessConfirmation: status, selectedAccessUserIndex: userIndex, removeAccessConfirmationMessage: (folder.members[userIndex].type == 'group') ? "Are you remove " + folder.members[userIndex].displayName + " group form this list?" : "Are you remove " + folder.members[userIndex].displayName + " form this list?" })
        }
    }

    private onRemoveAccess() {
        this.setState({ isProcessingRemoveAccess: true, showRemoveAccessConfirmation: false })
        MSGraphService.removeAccess({ driveInfo: this.state.selectedFolderInfo.driveInfo, folderInfo: this.state.selectedFolderInfo.spFolderInfo, userInfo: JSON.stringify(this.state.selectedFolderInfo.members[this.state.selectedAccessUserIndex]) }).then((resRemoveAccess: any) => {
            if (resRemoveAccess.status == "success") {
                if (this.state.selectedFolderInfo.members[this.state.selectedAccessUserIndex].type == 'group') {
                    toast.success("Group access removed", {
                        autoClose: 1000,
                        hideProgressBar: true
                    })
                } else {
                    toast.success("Uesr access removed", {
                        autoClose: 1000,
                        hideProgressBar: true
                    })
                }
                this.state.selectedFolderInfo.members.splice(this.state.selectedAccessUserIndex, 1)
                this.setState({ isProcessingRemoveAccess: false, selectedFolderInfo: this.state.selectedFolderInfo })
            } else {
                this.setState({ isProcessingRemoveAccess: false })
                toast.error("Try again.", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
            }
        }).catch((errorRemoveAccess: any) => {
            this.setState({ isProcessingRemoveAccess: false })
            toast.error("Try again.", {
                autoClose: 1000,
                hideProgressBar: true
            })
        })
    }

    private onSelectGroupOrUser(data: any, folderIndex: number) {
        this.setState({ isProcessingAddAccess: true, showRemoveAccessConfirmation: false })
        MSGraphService.updateAccess(data).then((resUpdateAccess: any) => {
            if (resUpdateAccess.status == "success") {
                toast.success((data.accessType == 'group') ? "Group access added" : "User access added", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
                this.state.contents[folderIndex].isMembersLoading = true;
                this.setState({ contents: this.state.contents })
                MSGraphService.getAccessedList({ folderInfo: this.state.contents[folderIndex].driveInfo }).then((resDataAccessList: any) => {
                    if (resDataAccessList.status == "success") {
                        this.state.contents[folderIndex].isMembersLoading = false;
                        this.state.contents[folderIndex].members = resDataAccessList.data;
                        this.setState({ contents: this.state.contents })
                    } else {
                        this.state.contents[folderIndex].accessListStatusMessage = "Try again";
                        this.state.contents[folderIndex].isMembersLoading = false;
                        this.setState({ contents: this.state.contents })
                    }
                }).catch((errorAccessList: any) => {
                    this.state.contents[folderIndex].accessListStatusMessage = "Try again";
                    this.state.contents[folderIndex].isMembersLoading = false;
                    this.setState({ contents: this.state.contents })
                })
                this.setState({ isProcessingAddAccess: false, selectedFolderInfo: this.state.selectedFolderInfo })
            } else {
                this.setState({ isProcessingAddAccess: false })
                toast.error("Try again.", {
                    autoClose: 1000,
                    hideProgressBar: true
                })
            }
        }).catch((errorRemoveAccess: any) => {
            this.setState({ isProcessingAddAccess: false })
            toast.error("Try again.", {
                autoClose: 1000,
                hideProgressBar: true
            })
        })
    }

    public render(): React.ReactElement<any> {
        return (
            <>
                <ToastContainer theme="colored" />
                {(this.state.isProcessingRemoveAccess || this.state.isProcessingAddAccess) ? <PopupLoader /> : ''}
                {this.state.contents.map((folder: any, folderIndex: number) => {
                    return <><div className="t-row" key={folderIndex}>
                        <div className="t-col">
                            <div className='sub-folder' style={{ margin: `${this.state.childFolderAlignment}` }}>
                                <div className="title-box" onClick={() => this.onSelectFolder(folder, folderIndex)}>
                                    <div className={(folder.isOpened) ? 'arrow open' : 'arrow'}>
                                        {(folder.childFolderCount > 0) ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path></svg> : <div className='arrow-dummy'></div>}
                                    </div>
                                    <div className="icon">
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                            viewBox="0 0 100 80"    ><g>
                                                <path fill="#F49D19" d="M88.6,10.8H44.8l-9.7-9.7H10.7C5.4,1.1,1,5.5,1,10.8v19.5h97.4v-9.7C98.4,15.2,94,10.8,88.6,10.8z" />
                                                <path fill="#FDC829" d="M88.6,10.8H10.7c-5.4,0-9.7,4.4-9.7,9.7v48.7c0,5.4,4.4,9.7,9.7,9.7h77.9c5.4,0,9.7-4.4,9.7-9.7V20.6
C98.4,15.2,94,10.8,88.6,10.8z"/></g>
                                        </svg>
                                    </div>
                                    <div className={(folder.isContainNonMembers) ? "title-nonuser" : "title"} > {folder.name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="t-col">
                            <div className="checkbox">
                                <input type='checkbox' onChange={(event) => { this.onClickInhert(event.currentTarget, folder, folderIndex) }} checked={folder.isInherit} disabled={!folder.isInheritApplicable} />
                            </div>
                        </div>
                        <div className="t-col">
                            <div className="members-list-container">
                                <GroupUserPicker team={this.props.team} folder={folder} onSelect={(data: any) => this.onSelectGroupOrUser(data, folderIndex)} disabled={(!folder.isInheritApplicable || folder.isInherit) ? true : false} />
                                <div className="members-list">
                                    {(folder.isMembersLoading) ? <div className='loader-box'><DataLoader /></div> : folder.members.map((member: any, memberIndex: number) => {
                                        return <div className="members-item" key={folderIndex + "_" + memberIndex}>
                                            <div className='icon'>
                                                {(member.type == "group") ? <svg className='group' xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M38-160v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q62 0 120 14t131 46q32 14 50.5 42.5T678-254v94H38Zm700 0v-94q0-63-32-103.5T622-423q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519-488q24-25 36.5-61.5T568-631q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108Z" /></svg> : <svg className='user' xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-160v-94q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5T731-360q31 14 50 41t19 65v94H160Z" /></svg>}
                                            </div>
                                            <div className="member-name">{member.displayName}</div>

                                            <div className={(!folder.isInheritApplicable || folder.isInherit) ? "member-remove disable" : "member-remove"} onClick={() => this.onShowRemoveAccessConfirmation(true, folder, memberIndex)}>
                                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Menu / Close_MD">
                                                        <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div >
                        {(folder.contents != undefined && folder.contents.length > 0 && (folder.isOpened != undefined && folder.isOpened)) ? <TreeViewTable data={folder.contents} team={this.props.team} channel={this.props.channel} user={this.props.user} parentLevel={this.props.parentLevel + 1} /> : ''
                        }
                        {(folder.isLoading != undefined && folder.isLoading) ? <><div className='loader-box'><DataLoader /></div></> : ''}</>

                })
                }

                <Modal show={this.state.showInhertConfirmation} onHide={() => this.onShowInhertConfirmation(false)} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>{this.state.inhertConfirmationMessage}</div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="button-container">
                            <button className="fe-btn black" onClick={() => this.onShowInhertConfirmation(false)}>No</button>
                            <button className="fe-btn blue" onClick={() => this.onUpdateInhertStatus()}>Yes</button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showRemoveAccessConfirmation} onHide={() => this.onShowRemoveAccessConfirmation(false)} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>{this.state.removeAccessConfirmationMessage}</div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="button-container">
                            <button className="fe-btn black" onClick={() => this.onShowRemoveAccessConfirmation(false)}>No</button>
                            <button className="fe-btn blue" onClick={() => this.onRemoveAccess()}>Yes</button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}