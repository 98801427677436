import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import "./USUsageOverview.scss";
import pdfIcon from "../../../assets/pdf_icon.png";
import excelIcon from "../../../assets/xlsx_icon.png";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Channel } from "diagnostics_channel";
import BarChart from "../BarChart/BarChart";
import reportData from "../../../assets/reportdata.json";
import { Global } from "../../../services/Global";
import { MSGraphService } from "../../../services/MSGraphService";
import Moment from "react-moment";
import moment from "moment";

export interface IUSUsageOverviewProps { }

export interface IUSUsageOverviewState {
  selectedChannel: string;
  timeFrame: string;
  startDate: Date;
  endDate: Date;
}

export default class USUsageOverview extends React.Component<
  any,
  any
> {
  private selectedTeam: any = null;
  private teamEmitterStatus: any = null;
  private rData: any = reportData;
  private topUsers = [
    "Zelma Storres",
    "Ex-VasilBlackshaw",
    "Lacie Grocock",
    "Ex-Kelvin Alpin",
    "Charlena hepard",
    "Ex-Florri Riach",
    "Arielle Gasken",
    "David Smith",
    "Emily Peterson",
    "Mark Dawson",
  ];

  public constructor(props: any, state: any) {
    const startDate = new Date();
    super(props);
    this.state = {
      selectedTeam: null,
      isChannelsLoader: true,
      channels: [],
      selectedChannel: 0,
      overallChatData: {
        labels: [],
        datasets: [
          {
            label: "Usage",
            data: [],
            borderColor: "#04AEC4",
            backgroundColor: "#cccccc",
          },
        ],
      },
      topUsersData: {
        users: [],
        data: []
      },
      timeFrame: "7",
      startDate: new Date(startDate.setDate(startDate.getDate() - 7)),
      endDate: new Date(),
    };
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Legend
    );
  }
  componentDidMount(): void {
    this.selectedTeam = Global.getSelectedTeam();
    if (this.selectedTeam !== null) {
      this.setState({ selectedTeam: this.selectedTeam })
      this.getTeamChannels();
    }
    this.teamEmitterStatus = Global.onTeamSelected.subscribe((data: any) => {
      this.selectedTeam = data;
      this.setState({ selectedTeam: data });
      this.getTeamChannels();
    })
  }

  componentWillUnmount(): void { }

  public getTeamChannels(): void {
    this.setState({ isChannelsLoader: true, channels: [] })
    MSGraphService.getTeamChannels({ teamId: this.selectedTeam.id }).then((resData: any) => {
      if (resData.status == "success") {
        resData.data.unshift({ id: "0", displayName: "All Channels" })
        this.setState({ isChannelsLoader: false, channels: resData.data });
        console.log(this.state.channels);
        this.onSelectChannel(0)

      } else {
        if (resData.message != "Request canceled by user.") {
          if (resData.message.indexOf("The SSL connection could not be established") >= 0) {
            this.getTeamChannels();
          } else {
            this.setState({ isChannelsLoader: false, channelStatusMessage: "Try again." })
          }
        }
      }
    }).catch((error: any) => {
      this.setState({ isChannelsLoader: false, channelStatusMessage: "Try again." })
    })
  }

  private onSelectChannel(channelIndex: any) {
    this.setState({ selectedChannel: channelIndex });
    console.log(channelIndex);
    this.loadOverallChart();
  }

  private loadOverallChart() {
    setTimeout(() => {
      this.setState({ overallChatData: this.getChannelOverallData(this.state.selectedChannel), topUsersData: this.getTopUsersData(this.state.selectedChannel) })
    }, 300);
  }

  private getChannelOverallData(channelIndex: number) {
    let sDate = moment(this.state.startDate);
    let eDate = moment(this.state.endDate);
    let days = eDate.diff(sDate, 'days')
    let graphLabels: any = [];
    let graphValues: any = [];
    for (let i = 0; i < days; i++) {
      var new_date = moment(sDate, "MM-DD-YYYY").add(i, 'days').format("MM-DD-YYYY");
      console.log(new_date);
      graphLabels.push(new_date)
      console.log(reportData);
      if (channelIndex == 0) {
        graphValues.push(this.rData.AllChannels[i]);
      } else {
        graphValues.push(this.rData["Channel" + channelIndex][i]);
      }

    }
    let data = {
      labels: graphLabels,
      datasets: [
        {
          label: "Usage",
          data: graphValues,
          borderColor: "#04AEC4",
          backgroundColor: "#cccccc",
        },
      ],
    }
    return data;
  }



  public getTopUsersData(channelIndex: number) {
    if (this.state.selectedChannel == 0) {
      return { users: this.rData.AllChannelUsers, data: this.rData.AllChannelUsersData }
    } else {
      return { users: this.rData["Channel" + channelIndex + "Users"], data: this.rData["Channel" + channelIndex + "UsersData"] }
    }
    // let arr1 = [16, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22];
    // let arr2 = [54, 12, 63, 55, 47, 22, 96, 65, 78, 65, 74, 31];
    // let arr3 = [82, 51, 13, 44, 85, 62, 74, 12, 3, 65, 12, 19];
    // let barChartData;
    // if (this.state.selectedChannel === "General") {
    //   barChartData = arr1;
    // } else if (this.state.selectedChannel === "Corporate") {
    //   barChartData = arr2;
    // } else {
    //   barChartData = arr3;
    // }
    // return barChartData;
  }

  public getTopAppsData() {
    let arr1 = [87, 29, 56, 52, 17, 38, 43, 27, 45, 70, 8, 69];
    let arr2 = [69, 87, 60, 93, 29, 29, 24, 6, 32, 41, 13, 67];
    let arr3 = [22, 17, 91, 55, 41, 61, 86, 31, 90, 45, 50, 74];
    let barChartData;
    if (this.state.selectedChannel === "General") {
      barChartData = arr1;
    } else if (this.state.selectedChannel === "Corporate") {
      barChartData = arr2;
    } else {
      barChartData = arr3;
    }
    return barChartData;
  }

  public add(x: number, y: number) {
    console.log("called add");
    return x + y;
  }
  public render() {
    // This is for the line chart
    const lineOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: true,
          text: "Overall Usage",
        },
      },
    };
    // const LINE_CHART_DATA = this.getLineChartData();
    // This is for the bar chart
    const APPS_LABELS = [
      "Posts",
      "App",
      "Extranet",
      "IM",
      "App3",
      "App7",
      "App8",
      "App5",
      "Mail",
      "Chat",
      "Connect",
      "Order",
    ];

    //const TOP_USERS_DATA = this.getTopUsersData();
    // const TOP_APPS_DATA = this.getTopAppsData();
    return (
      <div>
        <div className="data-section-header">
          <div className="data-select">
            <select
              className="form-select"
              aria-label="Default select"
              defaultValue={"General"}
              onChange={(event) => {
                this.onSelectChannel(event.target.value);
              }}
            >
              {this.state.channels.map((channel: any, channelIndex: number) => {
                return <option value={channelIndex} key={channel.id
                  + "_" + channelIndex}>{channel.displayName}</option>;
              })}
              {/* <option value="General">General</option>
              <option value="Corporate">Corporate</option>
              <option value="Matter">Matter</option> */}
            </select>

            <div>
              <label className="start-date">Start Date: </label>
              <DatePicker
                className="form-select"
                selected={this.state.startDate}
                onChange={(userDate) => {
                  let selectedDate;
                  if (userDate !== null) {
                    selectedDate = userDate.getTime(); // gives us milliseconds
                  } else {
                    selectedDate = null;
                  }
                  let lastDates = this.state.endDate.getTime();
                  if (
                    userDate !== null &&
                    selectedDate !== null &&
                    selectedDate <= lastDates
                  ) {
                    this.setState({ startDate: userDate });
                    this.loadOverallChart();
                  }
                }}
              />
            </div>
            <div>
              <label className="start-date">End Date: </label>
              <DatePicker
                className="form-select"
                selected={this.state.endDate}
                onChange={(date: Date) => {
                  const present = new Date().getTime(); //20 thousand milliseconds
                  const chosenDate = date.getTime();
                  if (chosenDate !== null && chosenDate <= present) {
                    console.log("The End Date is ", date);
                    this.setState({ endDate: date });
                    this.loadOverallChart();
                  }
                }}
              />
            </div>
          </div>
          {/* <div className="date">07-04-2022 to 14-04-2022</div> */}
          {/* <div className="icon-links">
            <a href="#" className="icon">
              <img src={pdfIcon} alt="PDF-Icon" />
            </a>
            <a href="#" className="icon">
              <img src={excelIcon} alt="EXcel-Icon" />
            </a>
          </div> */}
        </div>
        {/* line chart */}
        <div className="line-charts-container">
          <Line options={lineOptions} data={this.state.overallChatData} />
        </div>
        {/* left bar chart */}
        {/* <div className="bar-charts-container">
          <div className="bar-charts">
            <Bar options={userOptions} data={TOP_USERS_DATA} />
          </div>
          <div className="bar-charts">
            <Bar options={appsOptions} data={TOP_APPS_DATA} />
          </div>
        </div> */}
        <div className="bar-charts-container">
          <div className="bar-charts">
            <BarChart
              title="Top Users"
              labels={this.state.topUsersData.users}
              chartData={this.state.topUsersData.data}
              borderColor={"rgb(255, 99, 132)"}
              backGroundColor={"rgba(255, 99, 132, 0.5)"}
              legend="Activities"
            />
          </div>
          {/* <div className="bar-charts">
            <BarChart
              title="Top Apps"
              labels={APPS_LABELS}
              chartData={TOP_APPS_DATA}
              borderColor={"rgb(137, 207, 240)"}
              backGroundColor={"rgba(137, 207, 240, 0.5)"}
              legend="Days"
            />
          </div> */}
        </div>
        {/* right bar chart */}
        <div></div>
        {/* Files List */}
        <div></div>
      </div>
    );
  }
}
