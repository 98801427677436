import React from "react";
import "./ManagePermissions.scss";
import PageHeader from "../../components/PageHeader/PageHeader";
import MPTabMenusComponent from "./MPTabMenus/MPTabMenus";
import MPUserAccessComponent from "./MPUserAccess/MPUserAccess";
import MPGroupsComponent from "./MPGroups/MPGroups";
import MPFoldersComponent from "./MPFolders/MPFolders";

export default class ManagePermissionsPage extends React.Component<any, any> {
  public constructor(props: any, state: any) {
    super(props);
    this.state = {
      selectedTab: "user_access",
    };
  }

  componentDidMount(): void {}

  componentWillUnmount(): void {}

  private onSelectTab(data: string): void {
    this.setState({ selectedTab: data });
  }

  public render(): React.ReactElement<any> {
    return (
      <>
        <PageHeader></PageHeader>
        <div>
          <div className="permission-holder">
            <div className="fme-left-content">
              <div className="fme-content container">
                <div className="fme-container ">
                  <MPTabMenusComponent
                    onSelectTab={(data: any) => {
                      this.onSelectTab(data);
                    }}
                  ></MPTabMenusComponent>
                  <div className="tab-content" id="nav-tabContent">
                    {this.state.selectedTab === "user_access" ? (
                      <MPUserAccessComponent />
                    ) : (
                      ""
                    )}
                    {this.state.selectedTab === "groups" ? (
                      <MPGroupsComponent />
                    ) : (
                      ""
                    )}
                    {this.state.selectedTab === "folders" ? (
                      <MPFoldersComponent />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
