import uuid from 'react-uuid';
export class EventEmitter {
    public uid = uuid();
    private readonly _callBack: any = {}

    public subscribe(callBack: any): any {
        this.uid = uuid()
        this._callBack[this.uid] = callBack;
        return this.uid;
    }

    public unSubscribe(uid: any): void {
        try {
            this._callBack[uid] = null;
        } catch (ex) {
            console.log(ex)
        }
    }

    public emit(data: any): void {
        for (var x in this._callBack) {
            try {
                this._callBack[x](data)
            } catch (e) { }
        }
    }
}