import React from "react";
import "./UsageStatistics.scss";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Global } from "../../services/Global";
import USTabMenusComponent from "./USTabMenus/USTabMenus";
import USUsageOverview from "./USUsageOverview/USUsageOverview";
import USUserActivity from "./USUserActivity/USUserActivity";

export default class UsageStatisticsPage extends React.Component<any, any> {
  public constructor(props: any, state: any) {
    super(props);
    this.state = {
      selectedTab: "overview",
    };
  }

  private onSelectTab(data: string): void {
    this.setState({ selectedTab: data });
  }

  public render(): React.ReactElement<any> {
    return (
      <>
        <PageHeader></PageHeader>
        <div>
          <div className="permission-holder">
            <div className="fme-left-content">
              <div className="fme-content container">
                <div className="fme-container ">
                  <USTabMenusComponent
                    onSelectTab={(data: any) => {
                      this.onSelectTab(data);
                    }}
                  ></USTabMenusComponent>
                  <div className="tab-content" id="nav-tabContent">
                    {this.state.selectedTab === "overview" ? (
                      <USUsageOverview />
                    ) : (
                      ""
                    )}
                    {this.state.selectedTab === "activity" ? (
                      <USUserActivity />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
