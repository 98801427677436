import React from 'react'
import './Home.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import PageLoader from '../../components/PageLoader/PageLoader'
import { Global } from '../../services/Global';
import DataLoader from '../../components/DataLoader/DataLoader';
import Moment from 'react-moment';
import { MSGraphService } from '../../services/MSGraphService';

export default class HomePage extends React.Component<any, any>{
    private teamEmitterStatus: any = null;
    private selectedTeam: any = null;
    public constructor(props: any, state: any) {
        super(props)
        this.state = {
            isLoading: true,
            selectedTeam: null,
            isTeamOwnersLoader: false,
            standardChannels: 0,
            isStandardChannelsLoader: false,
            privateChannels: 0,
            isPrivateChannelsLoader: false,
            sharedChannels: 0,
            isSharedChannelsLoader: false,
            owners: []
        }
    }

    componentDidMount(): void {
        this.selectedTeam = Global.getSelectedTeam();
        if (this.selectedTeam !== null) {
            this.setState({ selectedTeam: this.selectedTeam, owners: [], isLoading: false, isTeamOwnersLoader: true, isStandardChannelsLoader: true, isPrivateChannelsLoader: true, isSharedChannelsLoader: true })
            this.getTeamOwners()
            this.getTeamChannelsCount()
        }
        this.teamEmitterStatus = Global.onTeamSelected.subscribe((data: any) => {
            if (data != undefined) {
                this.selectedTeam = data;
                this.setState({ selectedTeam: data, isLoading: false, isTeamOwnersLoader: true, isStandardChannelsLoader: true, isPrivateChannelsLoader: true, isSharedChannelsLoader: true, owners: [] })
                this.getTeamOwners()
                this.getTeamChannelsCount()
            }
        })
    }

    componentWillUnmount(): void {
        Global.onTeamSelected.unSubscribe(this.teamEmitterStatus);
    }

    public getTeamOwners(): void {
        this.setState({ isTeamOwnersLoader: true })
        MSGraphService.getOwners({ teamId: this.selectedTeam.id }).then((resData) => {
            if (resData.status === "success") {
                this.setState({ owners: resData.data, isTeamOwnersLoader: false })
            } else {
                if (resData.message != "Request canceled by user.") {
                    this.setState({ owners: [], isTeamOwnersLoader: false })
                }
            }
        }).catch((error) => {
            this.setState({ owners: [], isTeamOwnersLoader: false })
        })

    }

    public getTeamChannelsCount(): void {
        this.setState({ isStandardChannelsLoader: true, isPrivateChannelsLoader: true, isSharedChannelsLoader: true })
        MSGraphService.getChannelsCount({ teamId: this.selectedTeam.id }).then((resData) => {
            if (resData.status === "success") {
                setTimeout(() => {
                    this.setState({ standardChannels: resData.standardChannels, privateChannels: resData.privateChannels, sharedChannels: resData.sharedChannels, isStandardChannelsLoader: false, isPrivateChannelsLoader: false, isSharedChannelsLoader: false })
                }, 5000)

            } else {
                if (resData.message != "Request canceled by user.") {
                    this.setState({ standardChannels: 0, privateChannels: 0, sharedChannels: 0, isStandardChannelsLoader: false, isPrivateChannelsLoader: false, isSharedChannelsLoader: false })
                }
            }
        }).catch((error) => {
            this.setState({ standardChannels: 0, privateChannels: 0, sharedChannels: 0, isStandardChannelsLoader: false, isPrivateChannelsLoader: false, isSharedChannelsLoader: false })
        })
    }

    public render(): React.ReactElement<any> {
        return (!this.state.isLoading && this.selectedTeam != null) ? (<><PageHeader></PageHeader><div><div className="home-holder">
            <div className="fme-content ">
                <div className='fme-container'>
                    <div className="fme-data-list">
                        <div className="fme-data-col">
                            <div className="title">Teams Name</div>
                        </div>
                        <div className="fme-input-col">
                            <div className="value">{this.selectedTeam.displayName}</div>
                        </div>
                    </div>
                    <div className="fme-data-list">
                        <div className="fme-data-col">
                            <div className="title">Teams Administrators</div>
                        </div>
                        <div className="fme-input-col">
                            <div className="value">
                                {(this.state.isTeamOwnersLoader) ? <DataLoader></DataLoader> : ''}
                                {(this.state.owners.length > 0) ? this.state.owners.map((owner: any, index: number) => <span key={index}>{owner.displayName}{((this.state.owners.length - 1) == index) ? '' : ','} </span>) : ''}
                            </div>
                        </div>
                    </div>
                    <div className="fme-data-list">
                        <div className="fme-data-col">
                            <div className="title">Standard Channels </div>
                        </div>
                        <div className="fme-input-col">
                            <div className="value">{(this.state.isStandardChannelsLoader) ? <DataLoader></DataLoader> : this.state.standardChannels}
                            </div>
                        </div>
                    </div>
                    <div className="fme-data-list">
                        <div className="fme-data-col">
                            <div className="title">Private Channels </div>
                        </div>
                        <div className="fme-input-col">
                            <div className="value">
                                {(this.state.isPrivateChannelsLoader) ? <DataLoader></DataLoader> : this.state.privateChannels}
                            </div>
                        </div>
                    </div>
                    <div className="fme-data-list">
                        <div className="fme-data-col">
                            <div className="title">Shared Channels </div>
                        </div>
                        <div className="fme-input-col">
                            <div className="value">{(this.state.isSharedChannelsLoader) ? <DataLoader></DataLoader> : this.state.sharedChannels}
                            </div>
                        </div>
                    </div>
                    <div className="fme-data-list">
                        <div className="fme-data-col">
                            <div className="title">Teams Created Date</div>
                        </div>
                        <div className="fme-input-col">
                            <div className="value"><Moment format="MM/DD/YYYY HH:ss:mm">{this.selectedTeam.createdDateTime}</Moment></div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="fme-data-list">
                    </div>
                </div>
            </div>
        </div></div></>) : (<><div><PageLoader></PageLoader></div></>)
    }
}