import * as React from 'react'
import './MPGroups.scss'
import DataLoader from '../../../components/DataLoader/DataLoader'
import { MSGraphService } from '../../../services/MSGraphService'
import { Global } from '../../../services/Global'
import TreeView from '../../../components/TreeView/TreeView'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SiteSelector from '../../../components/SiteSelector/SiteSelector'
import { json } from 'stream/consumers'
import PageLoader from '../../../components/PageLoader/PageLoader'
import PopupLoader from '../../../components/PopupLoader/PopupLoader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class MPGroupsComponent extends React.Component<any, any> {
  private selectedTeam: any = null;
  private selectedGroup: any = null;
  private selectedChannel: any = null;
  private teamEmitterStatus: any = null;
  private siteEmitterStatus: any = null;

  public constructor(props: any, state: any) {
    super(props)
    this.state = {
      isGroupsLoader: true,
      groupStatusMessage: "",
      teamAccessGroups: [],
      selectedGroup: null,
      isGroupUsersLoader: true,
      groupUsersStatusMessage: "",
      groupUsers: [],
      isGroupContentParentLoader: true,
      groupContents: [],
      groupContentsMessage: "",
      showAddGroup: false,
      groupName: "",
      isAddGroupLoader: false,
      memberName: "",
      suggestedMembers: [],
      isShowSuggestedMembersList: false,
      isSuggestedMembersLoader: false,
      selectedMembers: [],
      isMemberAddLoader: false,
      selectedUser: null,
      showUserDeleteConfirmation: false,
      isMemberDeleteLoader: false,
      selectedSite: null,
      showToaster: false
    }
  }

  componentDidMount(): void {
    this.selectedTeam = Global.getSelectedTeam();
    if (this.selectedTeam !== null) {
      this.setState({ selectedTeam: this.selectedTeam, groupStatusMessage: "", groupUsersStatusMessage: "", groupContentsMessage: "" })
      this.getGroups();
    }
    this.teamEmitterStatus = Global.onTeamSelected.subscribe((data: any) => {
      this.selectedTeam = data;
      this.setState({ selectedTeam: data, isGroupsLoader: true, teamAccessGroups: [], groupStatusMessage: "", groupUsersStatusMessage: "", groupContentsMessage: "" });
      this.getGroups();
    })
    this.siteEmitterStatus = Global.onSiteSelected.subscribe((data: any) => {
      this.setState({ selectedSite: data })
    })
  }

  componentWillUnmount(): void {
    Global.onTeamSelected.unSubscribe(this.teamEmitterStatus);
    Global.onSiteSelected.unSubscribe(this.siteEmitterStatus);
  }

  public getGroups(): void {
    if (this.selectedTeam == undefined || this.selectedTeam == null || this.selectedTeam.id == undefined || this.selectedTeam.id == null) return;
    this.setState({ isGroupsLoader: true, groupStatusMessage: "", groupUsersStatusMessage: "", groupContentsMessage: "" })
    MSGraphService.getTeamAccessGroups({ teamId: this.selectedTeam.id }).then((resData) => {
      if (resData.status === "success") {
        this.setState({ teamAccessGroups: resData.data, isGroupsLoader: false })
        this.onSelectGroup(resData.data[0]);
      } else {
        if (resData.message != "Request canceled by user.") {
          this.setState({ isGroupsLoader: false, groupStatusMessage: "Try again later." })
        }
      }
    }).catch((error) => {
      this.setState({ isGroupsLoader: false })
    })
  }

  public onSelectGroup(group: any): void {
    this.selectedGroup = group;
    this.setState({ selectedGroup: group });
    this.getGroupMembers(group);
    this.getGroupRootContents(group);
    // let gropUrl = `${group.SiteUrl}/_layouts/15/people.aspx?MembershipGroupId=${group.UserSelection}`
    // window.open(gropUrl);
  }

  public getGroupMembers(group: any) {
    if (this.selectedTeam == undefined || this.selectedTeam == null || this.selectedGroup == undefined || this.selectedGroup == null) return;
    this.setState({ isGroupUsersLoader: true, groupUsersStatusMessage: "", groupUsers: [] });
    MSGraphService.getSharePointGroupMembers(group, this.selectedTeam.id).then((resData) => {
      if (resData.status === "success") {
        this.setState({ groupUsers: resData.data, isGroupUsersLoader: false })
      } else {
        if (resData.message != "Request canceled by user.") {
          this.setState({ isGroupUsersLoader: false, groupUsersStatusMessage: "Try again later." })
        }
      }
    }).catch((error) => {
      this.setState({ isGroupUsersLoader: false })
    })
  }

  public getGroupRootContents(group: any) {
    if (this.selectedTeam == undefined || this.selectedTeam == null || this.selectedGroup == undefined || this.selectedGroup == null) return;
    this.setState({ isGroupContentParentLoader: true, groupContentsMessage: "", groupContents: [] });
    MSGraphService.getGroupRootContents(group, this.selectedTeam.id).then((resData) => {
      if (resData.status === "success") {
        this.setState({ groupContents: resData.data, isGroupContentParentLoader: false })
      } else {
        if (resData.message != "Request canceled by user.") {
          this.setState({ isGroupContentParentLoader: false, groupContentsMessage: "Try again later." })
        }
      }
    }).catch((error) => {
      this.setState({ isGroupContentParentLoader: false })
    })
  }

  public onSelectRootContent(channel: any, index: number) {
    if (this.selectedTeam == undefined || this.selectedTeam == null || this.selectedGroup == undefined || this.selectedGroup == null || channel.childFolderCount == 0) return;
    this.state.groupContents[index].isOpened = (this.state.groupContents[index].isOpened == undefined) ? true : !this.state.groupContents[index].isOpened;
    if (this.state.groupContents[index].contents != undefined && this.state.groupContents[index].contents.length > 0) {
      this.setState({ groupContents: this.state.groupContents })
      return;
    }
    this.state.groupContents[index].isLoading = true
    this.setState({ groupContents: this.state.groupContents })
    let sendData = { teamId: this.selectedTeam.id, groupId: this.selectedGroup.id, groupName: this.selectedGroup.loginName, channelId: channel.id, channelType: channel.membershipType, folderId: "" }
    MSGraphService.getGroupContents(sendData).then((resData) => {
      if (resData.status === "success") {
        this.state.groupContents[index].isLoading = false;
        this.state.groupContents[index].contents = resData.data;
        this.setState({ groupContents: this.state.groupContents })
      } else {
        this.state.groupContents[index].isLoading = false;
        if (resData.message != "Request canceled by user.") {
          this.setState({ groupContents: this.state.groupContents, isGroupContentParentLoader: false, groupContentsMessage: "Try again later." })
        } else {
          this.setState({ groupContents: this.state.groupContents })
        }
      }
    }).catch((error) => {
      this.state.groupContents[index].isLoading = false;
      this.setState({ isGroupContentParentLoader: false })
    })
  }

  private onShowAddGroup(status: boolean) {
    this.setState({ showAddGroup: status })
  }

  private onSelectSiteInfo() {

  }

  private onSaveGroup() {
    this.setState({ isAddGroupLoader: true })
    let sendData = { teamId: this.selectedTeam.id, groupName: this.state.groupName, siteInfo: Global.encryptData(JSON.stringify({ SiteId: this.state.selectedSite.siteId, SiteUrl: this.state.selectedSite.siteUrl, TenantId: this.state.selectedSite.tenantId })) }
    MSGraphService.addGroup(sendData).then((resData) => {
      if (resData.status === "success") {
        toast.success("Group added", {
          autoClose: 1000,
          hideProgressBar: true
        })
        this.state.teamAccessGroups.unshift(resData.data);
        this.setState({ teamAccessGroups: this.state.teamAccessGroups, showAddGroup: false, isAddGroupLoader: false, selectedSite: null, groupName: "" })
        this.onSelectGroup(resData.data);
      } else {
        toast.error("Try again later.", {
          autoClose: 1000,
          hideProgressBar: true
        })
        this.setState({ isAddGroupLoader: false })
        if (resData.message != "Request canceled by user.") {

        } else {

        }
      }
    }).catch((error) => {
      toast.error("Try again later.", {
        autoClose: 1000,
        hideProgressBar: true
      })
      this.setState({ isAddGroupLoader: false })
    })
    //this.setState({ showAddGroup: false })
  }

  private onChangeMemberName(event: any) {
    this.setState({ memberName: event.target.value })
    if (event.target.value != "") {
      this.setState({ isSuggestedMembersLoader: true })
      MSGraphService.getTeamMembers({ teamId: this.selectedTeam.id, searchString: event.target.value }).then((resData) => {
        if (resData.status == "success") {
          this.setState({ isSuggestedMembersLoader: false, suggestedMembers: resData.data })
        } else {
          if (resData.message != "Request canceled by user.") {

          } else {
            this.setState({ isSuggestedMembersLoader: false })
          }
        }
      }).catch((error) => {
        this.setState({ isSuggestedMembersLoader: false })
      })
    } else {
      this.setState({ suggestedMembers: [] })
    }
  }


  // private onShowAddMember(status: boolean) {
  //   if (!status) {
  //     this.setState({ showAddMember: status, isMembersLoader: true, teamMembers: [], selectedMembers: [] })
  //   } else {
  //     this.setState({ showAddMember: status, isMembersLoader: true, teamMembers: [] })
  //   }

  //   this.getAllTeamMembers()
  // }

  // private getAllTeamMembers() {
  //   MSGraphService.getAllTeamMembers({ teamId: this.selectedTeam.id }).then((resData) => {
  //     if (resData.status === "success") {
  //       this.setState({ isMembersLoader: false, teamMembers: resData.data })
  //     } else {
  //       this.setState({ isMembersLoader: false })
  //       if (resData.message != "Request canceled by user.") {

  //       } else {

  //       }
  //     }
  //   }).catch((error) => {
  //     this.setState({ isMembersLoader: false })
  //   })
  // }

  private onSelectMember(member: any) {
    member.title = member.displayName;
    member.email = member.mail;
    member.isTeamMember = true;
    let memberExist = this.state.groupUsers.find((mem: any) => mem.email.toLowerCase() == member.mail.toLowerCase());
    if (memberExist == undefined || memberExist == null) {
      let sendData = JSON.parse(JSON.stringify(this.state.selectedGroup));
      sendData.email = member.email;
      sendData.displayName = member.displayName;
      this.setState({ isMemberAddLoader: true })
      MSGraphService.addGroupMember({ teamId: this.selectedTeam.id, groupInfo: sendData }).then((resData: any) => {
        if (resData.status == "success") {
          this.state.groupUsers.unshift(resData.data);
          this.setState({ isMemberAddLoader: false, groupUsers: this.state.groupUsers, memberName: "", teamMembers: [], selectedMembers: this.state.selectedMembers, suggestedMembers: [] })
          toast.success('User added.', {
            autoClose: 1000,
            hideProgressBar: true
          })
          // this.showToaster({ title: "Success", message: "User added." });
        } else {
          toast.error('Try again later.', {
            autoClose: 1000,
            hideProgressBar: true
          })
          // this.showToaster({ title: "Warning", message: "Try again later." });
          this.setState({ isMemberAddLoader: false, suggestedMembers: [], memberName: "" })
          if (resData.message != "Request canceled by user.") {

          } else {

          }
        }
      }).catch((error: any) => {
        toast.error('Try again later.', {
          autoClose: 1000,
          hideProgressBar: true
        })
        // this.showToaster({ title: "Warning", message: "Try again later." });
        this.setState({ isMemberAddLoader: false, suggestedMembers: [], memberName: "" })
      })
    } else {
      toast.warning('Member already exist in the group.', {
        autoClose: 1000,
        hideProgressBar: true
      })
      // this.showToaster({ title: "Warning", message: "Member already exist in the group." });
    }
  }

  private onSaveMember() {

  }

  private onShowUserDeleteConfirmation(status: boolean, user: any = null) {
    this.setState({ showUserDeleteConfirmation: status, selectedUser: user })
  }

  private onRemoveMember(member: any) {
    let deleteUserInfo = JSON.parse(JSON.stringify(this.state.selectedGroup));
    deleteUserInfo.memberId = member.id;
    this.setState({ isMemberDeleteLoader: true, showUserDeleteConfirmation: false })
    MSGraphService.removeGroupMember({ teamId: this.selectedTeam.id, userInfo: deleteUserInfo }).then((resData: any) => {
      if (resData.status === "success") {
        let deleteUserIndex = this.state.groupUsers.findIndex((user: any) => user.id == member.id);
        this.state.groupUsers.splice(deleteUserIndex, 1);
        this.setState({ isMemberDeleteLoader: false, groupUsers: this.state.groupUsers, teamMembers: this.state.teamMembers, suggestedMembers: [], memberName: "" })
        toast.success('User removed.', {
          autoClose: 1000,
          hideProgressBar: true
        })
        // this.showToaster({ title: "Success", message: "User removed" });
      } else {
        this.setState({ isMemberDeleteLoader: false })
        toast.error('Try again later.', {
          autoClose: 1000,
          hideProgressBar: true
        })
        // this.showToaster({ title: "Warning", message: "Try again later." });
        if (resData.message != "Request canceled by user.") {

        } else {

        }
      }
    }).catch((error: any) => {
      toast.error('Try again later.', {
        autoClose: 1000,
        hideProgressBar: true
      })
      // this.showToaster({ title: "Warning", message: "Try again later." });
      this.setState({ isMemberDeleteLoader: false })
    })
  }

  // private showToaster(data: any) {
  //   this.setState({ showToaster: true, toasterTitle: data.title, toasterMessage: data.message });
  //   setTimeout(() => {
  //     this.setState({ showToaster: false, toasterTitle: "", toasterMessage: "" });
  //   }, 3000)
  // }

  public render(): React.ReactElement<any> {
    return (
      <><ToastContainer theme="colored" />
        {(this.state.isMemberAddLoader || this.state.isMemberDeleteLoader) ? <PopupLoader /> : ''}
        <div className="manage-permissions">
          <div className="row">

            <div className="col-lg-3 col-md-6 ">
              <div className="card group-details">
                <div className="card-header">
                  Groups
                  <a className='add-icon' onClick={() => this.onShowAddGroup(true)}>
                    <svg version="1.1" baseProfile="basic" id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
                      <path fill='#295C91;' d="M24.2,35.4L24.2,35.4c1.1,0,1.9-0.9,1.9-1.9v-5.3c0-1.1,0.9-1.9,1.9-1.9h5.4c1.1,0,1.9-0.9,1.9-1.9v0
                    c0-1.1-0.9-1.9-1.9-1.9h-5.4c-1.1,0-1.9-0.9-1.9-1.9v-5.8c0-1.1-0.9-1.9-1.9-1.9h0c-1.1,0-1.9,0.9-1.9,1.9v5.8
                    c0,1.1-0.9,1.9-1.9,1.9h-5.7c-1.1,0-1.9,0.9-1.9,1.9v0c0,1.1,0.9,1.9,1.9,1.9h5.7c1.1,0,1.9,0.9,1.9,1.9v5.3
                    C22.2,34.5,23.1,35.4,24.2,35.4z M24,47.5c-3.3,0-6.4-0.6-9.2-1.8c-2.9-1.2-5.3-2.9-7.5-5c-2.1-2.1-3.8-4.6-5-7.5
                    c-1.2-2.8-1.8-5.9-1.8-9.2c0-3.3,0.6-6.4,1.8-9.2c1.2-2.9,2.9-5.4,5-7.5c2.1-2.1,4.6-3.8,7.5-5s5.9-1.9,9.2-1.9
                    c3.3,0,6.4,0.6,9.2,1.9c2.9,1.2,5.4,2.9,7.5,5c2.1,2.1,3.8,4.6,5,7.5c1.2,2.9,1.8,5.9,1.8,9.2c0,3.3-0.6,6.4-1.9,9.2
                    c-1.2,2.9-2.9,5.3-5,7.5c-2.1,2.1-4.6,3.8-7.5,5C30.4,46.9,27.3,47.5,24,47.5z"/>
                    </svg>
                  </a>
                </div>
                <div className="card-body max-height" >
                  <ul className="list-group">
                    {this.state.teamAccessGroups.length > 0
                      ? this.state.teamAccessGroups.map((spGroup: any) => {
                        return (<>{(spGroup.title != undefined && spGroup.title != null && spGroup.title != '') ? <li className={(spGroup.id == this.state.selectedGroup.id && spGroup.siteReference == this.state.selectedGroup.siteReference) ? 'list-group-item active' : 'list-group-item'} key={spGroup.id} onClick={() => this.onSelectGroup(spGroup)} title={spGroup.title}>
                          {spGroup.title}
                          {/* {spGroup.Title.replace(this.selectedTeam.displayName, '').trim()} */}
                        </li> : ''}</>

                        )
                      })
                      : (!this.state.isGroupsLoader) ? (this.state.groupStatusMessage != "") ? <><div className='retry'><span>{this.state.groupStatusMessage}</span> <span onClick={() => this.getGroups()}><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-160q-133 0-226.5-93.5T160-480q0-133 93.5-226.5T480-800q85 0 149 34.5T740-671v-129h60v254H546v-60h168q-38-60-97-97t-137-37q-109 0-184.5 75.5T220-480q0 109 75.5 184.5T480-220q83 0 152-47.5T728-393h62q-29 105-115 169t-195 64Z" /></svg></span></div></> : 'No groups found.' : ''}
                  </ul>
                  {(this.state.isGroupsLoader) ? <DataLoader /> : ''}
                </div>
              </div>
            </div>

            {(this.state.teamAccessGroups.length > 0) ? <div className="col-lg-3 col-md-6 ">
              <div className="card channel-details">
                <div className="card-header">
                  Users
                </div>
                <div className="card-body max-height">
                  <div className='search-box'>

                    <div className="input-box">
                      <input type='search' placeholder='Team member name' value={this.state.memberName} onChange={(event) => this.onChangeMemberName(event)} />
                      {(this.state.isSuggestedMembersLoader) ? <DataLoader /> : ""}
                    </div>
                    {this.state.suggestedMembers.length > 0 ?
                      <div className="dropdown-box">
                        {this.state.suggestedMembers.map((member: any, memberIndex: number) => { return <div className="dropdown-item" key={memberIndex} onClick={() => this.onSelectMember(member)}>{member.displayName}</div> })}
                      </div> : ''}
                  </div>
                  <ul className="list-group">
                    {this.state.groupUsers.length > 0
                      ? this.state.groupUsers.map((user: any) => {
                        return (<>{(user.title != undefined && user.title != null && user.title != '') ? <li className={(user.isTeamMember) ? 'list-group-item' : 'list-group-item title-nonuser'} key={user.id} >
                          <div className="link">
                            <span className='text' title={user.title}>{user.title}</span>
                            <a className="remove" onClick={() => this.onShowUserDeleteConfirmation(true, user)}>
                              <svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                x="0px" y="0px" viewBox="0 0 48 48" overflow="visible" >
                                <path fill="#295C91" d="M16.1,32L16.1,32c0.8,0.8,2,0.7,2.7,0l3.7-3.7c0.8-0.8,2-0.7,2.7,0l3.8,3.8c0.8,0.8,2,0.7,2.7,0l0,0
                              c0.8-0.8,0.7-2,0-2.7l-3.8-3.8c-0.8-0.8-0.7-2,0-2.7l4.1-4.1c0.8-0.8,0.7-2,0-2.7l0,0c-0.8-0.8-2-0.7-2.7,0l-4.1,4.1
                              c-0.8,0.8-2,0.7-2.7,0l-4-4c-0.8-0.8-2-0.7-2.7,0l0,0c-0.8,0.8-0.7,2,0,2.7l4,4c0.8,0.8,0.7,2,0,2.7l-3.7,3.7
                              C15.3,30,15.3,31.2,16.1,32z M47.2,24.1c0,12.9-10.4,23.3-23.3,23.3S0.6,36.9,0.6,24.1S11,0.8,23.9,0.8S47.2,11.2,47.2,24.1z"/>
                              </svg>
                            </a>
                          </div>
                        </li> : ''}</>
                        )
                      })
                      : (!this.state.isGroupUsersLoader) ? (this.state.groupUsersStatusMessage != "") ? <><div className='retry'><span>{this.state.groupUsersStatusMessage}</span> <span onClick={() => this.getGroupMembers(this.selectedGroup)}><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-160q-133 0-226.5-93.5T160-480q0-133 93.5-226.5T480-800q85 0 149 34.5T740-671v-129h60v254H546v-60h168q-38-60-97-97t-137-37q-109 0-184.5 75.5T220-480q0 109 75.5 184.5T480-220q83 0 152-47.5T728-393h62q-29 105-115 169t-195 64Z" /></svg></span></div></> : 'No users found.' : ''}
                  </ul>
                  {(this.state.isGroupUsersLoader) ? <DataLoader /> : ''}
                </div>
              </div>
            </div> : ''}

            {(this.state.teamAccessGroups.length > 0) ? <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  Folders
                </div>
                <div className="card-body max-height tree-holder">
                  <div className="treeview-box">
                    <div className="main-list">
                      {(this.state.isGroupContentParentLoader) ? <DataLoader /> : ''}
                      {this.state.groupContents.length > 0 ?
                        this.state.groupContents.map((folder: any, fonderIndex: number) => {
                          return (<><div className="title-box" key={folder.id} onClick={() => this.onSelectRootContent(folder, fonderIndex)}>
                            <div className={(folder.isOpened) ? 'arrow open' : 'arrow'}>
                              {(folder.childFolderCount > 0) ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path></svg> : <div className='arrow-dummy'></div>}
                            </div>
                            <div className="icon">
                              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 100 80"    ><g>
                                  <path fill="#F49D19" d="M88.6,10.8H44.8l-9.7-9.7H10.7C5.4,1.1,1,5.5,1,10.8v19.5h97.4v-9.7C98.4,15.2,94,10.8,88.6,10.8z" />
                                  <path fill="#FDC829" d="M88.6,10.8H10.7c-5.4,0-9.7,4.4-9.7,9.7v48.7c0,5.4,4.4,9.7,9.7,9.7h77.9c5.4,0,9.7-4.4,9.7-9.7V20.6
                        C98.4,15.2,94,10.8,88.6,10.8z"/></g>
                              </svg>
                            </div>
                            <div className={(folder.isContainNonMembers) ? 'title-nonuser' : 'title'} title={folder.displayName}> {folder.displayName}</div>
                          </div>
                            {(folder.contents != undefined && folder.contents.length > 0 && (folder.isOpened != undefined && folder.isOpened)) ? <TreeView data={folder.contents} contentType="group" group={this.selectedGroup} team={this.selectedTeam} channel={folder} user={null} /> : ''}
                            {(folder.isLoading != undefined && folder.isLoading) ? <DataLoader /> : ''}
                          </>)
                        }) :
                        (!this.state.isGroupContentParentLoader) ? (this.state.groupContentsMessage != "") ? <><div className='retry'><span>{this.state.groupContentsMessage}</span> <span onClick={() => this.getGroupRootContents(this.selectedGroup)}><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-160q-133 0-226.5-93.5T160-480q0-133 93.5-226.5T480-800q85 0 149 34.5T740-671v-129h60v254H546v-60h168q-38-60-97-97t-137-37q-109 0-184.5 75.5T220-480q0 109 75.5 184.5T480-220q83 0 152-47.5T728-393h62q-29 105-115 169t-195 64Z" /></svg></span></div></> : 'No contents found.' : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div> : ''}
          </div>
        </div>
        <Modal show={this.state.showAddGroup} onHide={() => this.onShowAddGroup(false)} backdrop="static" keyboard={false}>

          <Modal.Header closeButton>
            <Modal.Title>Add Group</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="group-search">
              <SiteSelector />
              <input type='text' placeholder='Enter the group name' value={this.state.groupName} onChange={e => this.setState({ groupName: e.target.value })} disabled={(this.state.selectedSite == null) ? true : false} />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-container">
              <button className="fe-btn black" onClick={() => this.onShowAddGroup(false)}>Close</button>
              <button className="fe-btn blue" onClick={() => this.onSaveGroup()} disabled={(this.state.groupName.trim() == "") ? true : false}>Save
                {(this.state.isAddGroupLoader) ? <DataLoader></DataLoader> : ''}
              </button>
            </div>
            {/* <Button variant="secondary" onClick={() => this.onShowAddGroup(false)}>Close</Button>
              <Button variant="primary" onClick={() => this.onSaveGroup()} disabled={(this.state.groupName.trim() == "") ? true : false}>Save</Button> */}
          </Modal.Footer>

        </Modal>


        <Modal show={this.state.showUserDeleteConfirmation} onHide={() => this.onShowUserDeleteConfirmation(false)} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>Are you sure to remove this user? </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-container">
              <button className="fe-btn black" onClick={() => this.onShowUserDeleteConfirmation(false)}>No</button>
              <button className="fe-btn blue" onClick={() => this.onRemoveMember(this.state.selectedUser)}>Yes
                {(this.state.isMemberDeleteLoader) ? <DataLoader></DataLoader> : ''}
              </button>
            </div>
            {/* <Button variant="secondary" onClick={() => this.onShowAddGroup(false)}>Close</Button>
              <Button variant="primary" onClick={() => this.onSaveGroup()} disabled={(this.state.groupName.trim() == "") ? true : false}>Save</Button> */}
          </Modal.Footer>
        </Modal>
        {/* {(this.state.showToaster) ? <Toaster title={this.state.toasterTitle} message={this.state.toasterMessage} /> : ''} */}
      </>
    )
  }
}
