import React from 'react';
import './App.scss';
import PageLoader from './components/PageLoader/PageLoader';
import MSLogin from './components/MSLogin/MSLogin';
import { Global } from './services/Global';
import ExtraNet from './pages/ExtraNet';
export default class App extends React.Component<any, any>{

  private authStatus: any = null;
  public constructor(props: any, state: any) {
    super(props)
    this.state = {
      isPageLoading: true,
      showMSLogin: false,
      isAuthorized: false,
      appTheme: Global.appTheme
    }
    let _this = this;
    window.addEventListener('message', (event: any) => {
      if (event.origin === "https://teams.microsoft.com") {
        if (event.data && event.data.func === "themeChange") {
          Global.appTheme = event.data.args[0];
          _this.onUpdateTheme();
        }
      }
    });
  }

  componentDidMount(): void {
    Global.loadResource();
    this.setState({ isPageLoading: true });
    this.authStatus = Global.onAuthStatus.subscribe((authData: any) => {
      if (authData.status === 'success') {
        this.setState({ showMSLogin: false, isPageLoading: false, isAuthorized: true });
      } else {
        this.setState({ showMSLogin: true, isPageLoading: false, isAuthorized: false });
      }
    })
    setTimeout(() => {
      this.setState({ showMSLogin: true });
    })
  }

  componentWillUnmount(): void {
    Global.onAuthStatus.unSubscribe(this.authStatus);
  }

  private onUpdateTheme() {
    this.setState({ appTheme: Global.appTheme });
  }

  public render(): React.ReactElement<any> {
    return (
      <>
        {(this.state.isPageLoading) ? <PageLoader>Loading ...</PageLoader> : ''}
        {(this.state.showMSLogin) ? <MSLogin></MSLogin> : ''}
        {(this.state.isAuthorized) ? <ExtraNet theme={this.state.appTheme}></ExtraNet> : ''}
      </>
    );
  }
}

