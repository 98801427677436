import React from 'react'
import './PageLoader.scss'
export default class PageLoader extends React.Component<any, any>{

    public render(): React.ReactElement<any> {
        return (
            <div className="loading-animation">
                <div className="loading-holder">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            </div>
        );
    }
}