import React from 'react'
import './MSLogin.scss'
import { app, authentication } from '@microsoft/teams-js'
import { Global } from '../../services/Global'
import { toast } from 'react-toastify'
import { MSGraphService } from '../../services/MSGraphService'

export default class MSLogin extends React.Component<any, any>{

    public constructor(props: any, state: any) {
        super(props)
        this.state = {
            warningMessage: "",
        }
    }

    componentDidMount(): void {
        Global.appTheme = 'default';
        let accessToken = Global.getAccessToken();
        console.log(MSGraphService.APIURL);
        if (accessToken !== null && accessToken !== "") {
            if (Global.isAccessTokenExpired()) {
                app.initialize().then((resAppInit) => {
                    authentication.getAuthToken().then((res: any) => {
                        MSGraphService.getAccessToken({ idToken: res }).then((resData) => {
                            if (resData.status === 'success') {
                                Global.setAccessToken(resData.accessData);
                                this.getCurrentUser();
                            } else {
                                toast.error('Authorization Failed!', {
                                    autoClose: 1000,
                                    hideProgressBar: true
                                })
                            }
                        }).catch((error) => {
                            console.log("Access token error.");
                            console.error(error);
                        })
                    }, (error) => {
                        console.log("MS Teams application token not received.");
                        console.error(error);
                    })
                }).catch((error) => {
                    console.log("MS Teams application not initialized.");
                    console.error(error);
                })
            } else {
                Global.checkAccessTokenStatus();
                this.getCurrentUser();
            }
        } else {
            app.initialize().then((resAppInit) => {
                console.log(resAppInit)
                authentication.getAuthToken().then((res: any) => {
                    MSGraphService.getAccessToken({ idToken: res }).then((resData) => {
                        if (resData.status === 'success') {
                            Global.setAccessToken(resData.accessData);
                            this.getCurrentUser();
                        } else {
                            toast.error('Authorization Failed!', {
                                autoClose: 1000,
                                hideProgressBar: true
                            })
                        }
                    }).catch((error) => {
                        console.log("Access token error.");
                        console.error(error);
                    })
                }, (error) => {
                    console.log("MS Teams application token not received.");
                    console.error(error);
                })
            }).catch((error) => {
                console.log("MS Teams application not initialized.");
                console.error(error);
            })
        }
    }

    componentWillUnmount(): void {

    }

    public getCurrentUser() {
        MSGraphService.getCurrentUserInfo().then((resData: any) => {
            if (resData.status === 'success') {
                this.setState({ warningMessage: "" });
                Global.setCurrentUser(resData.data);
                Global.onAuthStatus.emit({ status: "success" })
            } else {
                this.setState({ warningMessage: resData.message });
                Global.onAuthStatus.emit({ status: "failed" })
            }
        }).catch((error: any) => {
            this.setState({ warningMessage: "Try agin later." });
            Global.onAuthStatus.emit({ status: "failed" })
            // Global.onAuthStatus.emit({ status: "success" })
        })

    }

    public render(): React.ReactElement<any> {
        return (<>{(this.state.warningMessage == "") ? <div className="loading-animation">
            <div className="loading-holder">
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div> : <div className='login-warning-container'><div className='login-warning-text'>{this.state.warningMessage}</div></div>}</>
        );
    }
}