import { EventEmitter } from "./EventEmitter";
//import Moment from 'react-moment';
import * as moment from 'moment'
import { MSGraphService } from "./MSGraphService";
import { authentication } from "@microsoft/teams-js";
import resource from "../assets/resources.json";
import axios from "axios";
export class Global {
    public static appTheme: string = "default"
    private static currentUser: any = null;
    private static accessToken: string = "";
    private static idToken: string = "";
    private static tokenExpirsOn: string = "";
    private static isTokenRequested: any = null;


    private static selectedTeam: any = null;
    public static resource: any = null;
    public static onAuthStatus: EventEmitter = new EventEmitter()
    public static onTeamSelected: EventEmitter = new EventEmitter()
    public static onSiteSelected: EventEmitter = new EventEmitter()
    public static onFolderSelected: EventEmitter = new EventEmitter()
    public static onTeamSelectorOutsideEvent: EventEmitter = new EventEmitter()
    public static onSiteSelectorOutsideEvent: EventEmitter = new EventEmitter()
    public static onGUPickerOutsideEvent: EventEmitter = new EventEmitter()

    public static loadResource(): void {
        Global.resource = resource;
        //console.log(resource);
    }


    public static setAccessToken(data: any): void {
        data.accessObtained = Date.now();
        console.log(Global.encryptData(JSON.stringify(data)));
        localStorage.setItem("accessData", Global.encryptData(JSON.stringify(data)))
        Global.accessToken = data.accessToken;
        Global.idToken = data.idToken;
        Global.tokenExpirsOn = data.expiresOn;
        this.checkAccessTokenStatus();
    }

    public static getAccessToken(): string {
        if (Global.accessToken != undefined && Global.accessToken != null && Global.accessToken != "") {
            return Global.accessToken;
        } else {
            let accessData: any = localStorage.getItem("accessData");
            if (accessData != undefined && accessData != null && accessData != "") {
                accessData = JSON.parse(Global.decryptData(accessData));
                Global.accessToken = accessData.accessToken;
                Global.idToken = accessData.idToken;
                Global.tokenExpirsOn = accessData.expiresOn;
                return Global.accessToken;
            } else {
                return "";
            }
        }
    }

    public static setCurrentUser(userInfo: any): void {
        this.currentUser = userInfo;
    }

    public static getCurrentUser(): any {
        return this.currentUser;
    }

    public static setSelectedTeam(selectedTeam: any): void {
        this.selectedTeam = selectedTeam;
    }

    public static getSelectedTeam(): any {
        return this.selectedTeam;
    }

    public static encryptData(stringData: string) {
        if (stringData == undefined && stringData == null && stringData == "") return "";
        let inputEncrypted: string = btoa(stringData);
        let memData = inputEncrypted.slice(-2);
        let dataForProcess = inputEncrypted.substring(0, inputEncrypted.length - 2);
        dataForProcess = dataForProcess.split("").reverse().join("");
        let position = (dataForProcess.length < 5) ? dataForProcess.length : 5;
        var dataOut = [dataForProcess.slice(0, position), this.getRandomString(), dataForProcess.slice(position)].join('') + memData;
        return dataOut;
    }

    public static decryptData(stringData: string) {
        if (stringData == undefined && stringData == null && stringData == "") return "";
        let memData = stringData.slice(-2);
        let dataForProcess = stringData.substring(0, stringData.length - 2);
        let actualStrLength = dataForProcess.length - 8;
        let position = (actualStrLength < 5) ? actualStrLength : 5;
        dataForProcess = dataForProcess.replace(dataForProcess.substring(position, (position + 8)), "").split("").reverse().join("") + memData;
        let dataOut: string = atob(dataForProcess);
        return dataOut;
    }

    private static getRandomString(stringLength = 8) {
        let randomString = "";
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < stringLength) {
            randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return randomString;
    }

    private static getRefreshToken() {
        if (this.isTokenRequested != null) return;
        this.isTokenRequested = MSGraphService.getAccessToken({ idToken: this.idToken }).then((resData) => {
            if (resData.status === 'success') {
                this.setAccessToken(resData.accessData);
                this.isTokenRequested = null;
            } else {
                this.isTokenRequested = null;
                console.error('Authorization Failed!');
            }
        }).catch((error) => {
            this.isTokenRequested = null;
            console.log("Access token error.");
        })
    }

    public static isAccessTokenExpired(): boolean {
        let tempAccessData: any = localStorage.getItem("accessData");
        tempAccessData = (tempAccessData != undefined && tempAccessData != null && tempAccessData != "") ? JSON.parse(Global.decryptData(tempAccessData)) : null;
        if (tempAccessData != null) {
            if (tempAccessData.expiresOn != undefined && tempAccessData.expiresOn != null && tempAccessData.expiresOn != "") {
                let expiresOnLocal = moment.utc(tempAccessData.expiresOn).local().format("DD-MM-YYYY HH:mm:ss");
                let currentLocal = moment.utc().local().format("DD-MM-YYYY HH:mm:ss");
                if (currentLocal >= expiresOnLocal) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    public static checkAccessTokenStatus() {
        setTimeout(() => {
            if (this.isAccessTokenExpired()) {
                this.getRefreshToken();
            } else {
                let tempAccessData: any = localStorage.getItem("accessData");
                tempAccessData = (tempAccessData != undefined && tempAccessData != null && tempAccessData != "") ? JSON.parse(Global.decryptData(tempAccessData)) : null;
                if (tempAccessData != null) {
                    console.log(tempAccessData);
                    let dateTo = moment.utc(tempAccessData.expiresOn).local();
                    let dateFrom = moment.now();
                    // console.log(dateTo);
                    // console.log(dateFrom);
                    // let mins = moment.utc(moment.utc(Global.tokenExpirsOn).local().diff(moment.utc().local())).format("mm");
                    let mins = dateTo.diff(dateFrom, "minutes")
                    // console.log(mins);
                    if (Number(mins) <= 30) {
                        this.getRefreshToken();
                    } else {
                        this.checkAccessTokenStatus();
                    }
                } else {
                    this.getRefreshToken();
                }
            }
        }, 60000 * 5)
    }
}

export const SIDEMENUS = {
    HOME: 'Home',
    MANAGE_PERMISSIONS: 'Manage_Permissions',
    USAGE_STATISTICS: 'Usage_Statistics',
    HELP: 'Help'
}