import * as React from 'react'
import './SiteSelector.scss'
// import AsyncSelect from 'react-select/async'
import { MSGraphService } from '../../services/MSGraphService'
import { Global } from '../../services/Global'
import DataLoader from '../DataLoader/DataLoader';
export default class SiteSelector extends React.Component<
    any,
    any
> {
    private outsideEvent: any = null;
    public searchString: string = "";
    private skipToken: string = "";
    private inputSearchReference: any = null;
    private containerReference: any;
    public constructor(props: any, state: any) {
        super(props)
        this.state = {
            isLoading: true,
            showSelectDropdown: false,
            searchString: "",
            selectedTeam: null,
            siteList: [],
            selectedSite: null
        }
        this.containerReference = React.createRef();

    }

    componentDidMount(): void {
        if (Global.getSelectedTeam() != null) {
            this.setState({ selectedTeam: Global.getSelectedTeam() })
        }
        setTimeout(() => {
            this.getSitesList();
        }, 1000)
        this.outsideEvent = Global.onSiteSelectorOutsideEvent.subscribe((data: any) => {
            this.setState({ showSelectDropdown: false })
        })
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount(): void {
        Global.onSiteSelectorOutsideEvent.unSubscribe(this.outsideEvent);
    }

    public handleClickOutside(event: any) {
        try {
            if (event.target.className.indexOf("team-select-element") < 0) {
                Global.onSiteSelectorOutsideEvent.emit(event.target.className);
            }
        } catch {
            Global.onSiteSelectorOutsideEvent.emit(event.target.className);
        }
    }

    public getSitesList(searchString: string = ""): void {
        this.setState({ isLoading: true });
        MSGraphService.getSharePointSites({ teamId: this.state.selectedTeam.id }).then((resData: any) => {
            if (resData.status === "success") {
                let tealsList: any = [];
                if (this.skipToken != null && this.skipToken != "") {
                    this.state.siteList.concat(resData.data);
                    this.setState({ siteList: this.state.siteList, isLoading: false })
                } else {
                    this.skipToken = resData.skipToken;
                    this.setState({ siteList: resData.data, isLoading: false })
                }
                if (this.state.selectedSite == null) this.onSelectSite(resData.data[0]);
            } else {
                if (resData.message != "Request canceled by user.") this.setState({ isLoading: false, siteList: [] });
                this.skipToken = "";
            }
        }).catch((error: any) => {
            this.setState({ isLoading: false });
            this.skipToken = "";
        })
    }

    public showDropDown(): void {
        this.setState({ showSelectDropdown: !this.state.showSelectDropdown })
        setTimeout(() => {
            this.inputSearchReference.focus();
        }, 100)
    }

    public hideDropDown(): void {
        this.setState({ showSelectDropdown: false })
    }

    public onChangeTeamSearch(event: any) {
        this.skipToken = "";
        this.searchString = event.target.value;
        this.getSitesList(this.searchString);
    }

    public onSelectSite(data: any): void {
        this.searchString = "";
        this.setState({ searchString: "" })
        this.setState({ selectedSite: data, showSelectDropdown: false })
        Global.onSiteSelected.emit(data);
    }

    public render(): React.ReactElement<any> {
        return (<>
            <div className='site-select-container team-select-element' ref={this.containerReference}>
                <div className='select-input team-select-element'>
                    <input className='team-select-element' type='text' placeholder='Select channel' value={this.state.selectedSite?.displayName} readOnly onClick={() => { this.showDropDown() }} />

                    <div className='icon-container team-select-element'>{(!this.state.showSelectDropdown && this.state.isLoading) ? <DataLoader></DataLoader> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" onClick={() => { this.showDropDown() }} ><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" /></svg>}
                    </div>
                </div>
                <div className={(this.state.showSelectDropdown === true) ? 'team-select-element site-select-dropdown show' : 'team-select-element select-dropdown'}>
                    <div className='input-box team-select-element'>
                        <input className='team-select-element' type='text' ref={(input) => { this.inputSearchReference = input; }} placeholder='Search Team' value={this.searchString} onChange={($event) => { this.onChangeTeamSearch($event) }} />
                        <div className='icon-container team-select-element'>{(this.state.showSelectDropdown && this.state.isLoading) ? <DataLoader></DataLoader> : ''}
                        </div>
                    </div>
                    <div className='select-list-container team-select-element'>
                        {this.state.siteList.map((item: any, index: number) => {
                            return <div className='select-list-item team-select-element' key={index} onClick={() => { this.onSelectSite(item) }}>{item.displayName}</div>
                        })}
                    </div>
                </div>
            </div>
        </>)
    }
}
