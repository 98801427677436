import React from 'react'
import './DataLoader.scss'
import spinner from '../../assets/spinner.gif'
export default class DataLoader extends React.Component<any, any>{
  public render(): React.ReactElement<any> {
    return (
      <div className="data-loading-holder">
        <div className="spinner-border" role="status">
          <span className='sr-only'></span>
        </div>
      </div>
    )
  }
}
