import * as React from 'react'
import './MPUserAccess.scss'
import { MSGraphService } from '../../../services/MSGraphService'
import { Global } from '../../../services/Global'
import DataLoader from '../../../components/DataLoader/DataLoader'
import TreeView from '../../../components/TreeView/TreeView'
export default class MPUserAccessComponent extends React.Component<any, any> {
  private teamEmitterStatus: any = null;
  private selectedTeam: any = null;
  private selectedChannel: any = null;
  private internalUserSkipToken: string = "";
  private externalUserSkipToken: string = "";
  private userGroupSkipToken: string = "";
  private channelContents: any = [];

  public constructor(props: any, state: any) {
    super(props)
    this.state = {
      userType: 'internal',
      selectedTeam: null,
      isInternalMembersLoader: true,
      internalUsersList: [],
      isExternalMembersLoader: true,
      externalUsersList: [],
      selectedUser: null,
      isChannelLoading: true,
      channelsList: [],
      selectedChannel: {},
      isUserGroupsLoader: true,
      userGroups: [],
      userGroupStatusMessage: "",
      isChannelContentLoader: true,
      channelContents: [],
      userFolderStatusMessage: "",
      isSelectedUserFoldersLoader: false,
    }
  }

  componentDidMount(): void {
    this.selectedTeam = Global.getSelectedTeam();
    if (this.selectedTeam !== null) {
      this.setState({ selectedTeam: this.selectedTeam })
      this.internalUserSkipToken = "";
      this.externalUserSkipToken = "";
      this.userGroupSkipToken = "";
      this.getInternalMembers();
      this.getExternalMembers();
    }
    this.teamEmitterStatus = Global.onTeamSelected.subscribe((data: any) => {
      this.selectedTeam = data;
      this.setState({ selectedTeam: data })
      this.internalUserSkipToken = "";
      this.externalUserSkipToken = "";
      this.userGroupSkipToken = "";
      this.setState({ isInternalMembersLoader: true, internalUsersList: [], isExternalMembersLoader: true, externalUsersList: [], selectedUser: null, userGroups: [], isUserGroupsLoader: true, channelContents: [], isChannelContentLoader: true })
      this.getInternalMembers();
      this.getExternalMembers();
    })
  }

  componentWillUnmount(): void {
    Global.onTeamSelected.unSubscribe(this.teamEmitterStatus);
    this.internalUserSkipToken = "";
    this.externalUserSkipToken = "";
    this.userGroupSkipToken = "";
  }

  public getInternalMembers(): void {
    this.setState({ isInternalMembersLoader: true })
    MSGraphService.getTeamInternalMembers({ teamId: this.selectedTeam.id, skipToken: this.internalUserSkipToken }).then((resData) => {
      if (resData.status === "success") {
        if (this.internalUserSkipToken != undefined && this.internalUserSkipToken != null && this.internalUserSkipToken != "") {
          let pagingData = this.state.internalUsersList.concat(resData.data);
          this.setState({ internalUsersList: pagingData, isInternalMembersLoader: false })
        } else {
          this.setState({ internalUsersList: resData.data, isInternalMembersLoader: false })
          if (this.state.userType == 'internal' && resData.data.length > 0) this.selectedUser(resData.data[0]);
        }
        this.internalUserSkipToken = resData.skipToken;

      }
    }).catch((error) => {
      this.internalUserSkipToken = "";
      this.setState({ isInternalMembersLoader: false })
    })
  }

  public getExternalMembers(): void {
    this.setState({ isExternalMembersLoader: true })
    MSGraphService.getTeamExternalMembers({ teamId: this.selectedTeam.id, skipToken: this.externalUserSkipToken }).then((resData) => {
      if (resData.status === "success") {
        if (this.externalUserSkipToken != undefined && this.externalUserSkipToken != null && this.externalUserSkipToken != "") {
          let pagingData = this.state.externalUsersList.concat(resData.data);
          this.setState({ externalUsersList: pagingData, isExternalMembersLoader: false })
        } else {
          this.setState({ externalUsersList: resData.data, isExternalMembersLoader: false })
          if (this.state.userType == 'external' && resData.data.length > 0) this.selectedUser(resData.data[0]);
        }
        this.externalUserSkipToken = resData.skipToken;
      }
    }).catch((error) => {
      this.externalUserSkipToken = "";
      this.setState({ isExternalMembersLoader: false })
    })
  }

  private selectedUser(user: any): void {
    this.userGroupSkipToken = "";
    this.setState({ selectedUser: user, channelsList: [], isChannelLoading: true, userGroups: [], isUserGroupsLoader: true, channelContents: [], isChannelContentLoader: true })
    setTimeout(() => {
      this.getUserGroups();
      this.getUserChannels();
    }, 50)
  }

  public getUserChannels(): void {
    if (this.state.selectedUser == null) return;
    this.setState({ isChannelLoading: true, channelsList: [] })
    MSGraphService.getUserChannels({ userId: this.state.selectedUser.id, teamId: this.selectedTeam.id }).then((resData) => {
      if (resData.status === "success") {
        this.setState({ channelsList: resData.data, isChannelLoading: false })
        if (resData.data.length > 0) this.onSelectChannel(resData.data[0]);
      }
    }).catch((error) => {
      this.setState({ isChannelLoading: false })
    })
  }

  private onSelectChannel(channel: any): void {
    this.selectedChannel = channel;
    this.setState({ selectedChannel: channel })
    setTimeout(() => {
      this.userGroupSkipToken = "";
      this.getChannelContents();
    }, 50)
  }

  public getUserGroups(): void {
    if (this.state.selectedUser == undefined || this.state.selectedUser == null) return;
    this.setState({ isUserGroupsLoader: true, userGroupStatusMessage: "" })
    MSGraphService.getSharePointGroupsByUser({ userId: this.state.selectedUser.id, userEmail: this.state.selectedUser.mail, teamId: this.selectedTeam.id, channelId: this.state.selectedChannel.id, skipToken: this.userGroupSkipToken }).then((resData) => {
      if (resData.status === "success") {
        if (this.userGroupSkipToken != undefined && this.userGroupSkipToken != null && this.userGroupSkipToken != "") {
          let pagingData = this.state.userGroups.concat(resData.data);
          this.setState({ userGroups: pagingData, isUserGroupsLoader: false })
        } else {
          this.setState({ userGroups: resData.data, isUserGroupsLoader: false })
        }
        this.userGroupSkipToken = resData.skipToken;
      } else {
        this.setState({ isUserGroupsLoader: false, userGroupStatusMessage: "Try again later." })
      }
    }).catch((error) => {
      this.userGroupSkipToken = "";
      this.setState({ isUserGroupsLoader: false })
    })
  }

  public getChannelContents(): void {
    if (this.state.selectedUser == null || this.state.selectedChannel == null) return;
    this.setState({ isChannelContentLoader: true, channelContents: [], userFolderStatusMessage: "" })
    MSGraphService.getChannelContents({ teamId: this.selectedTeam.id, userId: this.state.selectedUser.id, channelId: this.selectedChannel.id, folderId: 'contents', channelType: this.selectedChannel.membershipType, userType: this.state.selectedUser.userType.toLowerCase(), userMail: this.state.selectedUser.mail }).then((resData) => {
      if (resData.status === "success") {
        this.setState({ channelContents: resData.data, isChannelContentLoader: false })
      } else {
        this.setState({ channelContents: [], isChannelContentLoader: false, userFolderStatusMessage: "Try again later." })
      }
    }).catch((error) => {
      this.setState({ isChannelContentLoader: false })
    })
  }

  private setUserType(userType: string): void {
    this.setState({ userType })
  }


  private onScrollInternalUsers(event: any) {
    if ((event.target.scrollHeight - event.target.offsetHeight) <= event.target.scrollTop) {
      if (this.internalUserSkipToken == "" || this.state.isInternalMembersLoader) return;
      this.getInternalMembers();
    }
  }

  private onScrollExternalUsers(event: any) {
    if ((event.target.scrollHeight - event.target.offsetHeight) <= event.target.scrollTop) {
      if (this.externalUserSkipToken == "" || this.state.isExternalMembersLoader) return;
      this.getExternalMembers();
    }
  }

  // private onScrollUserGroups(event: any) {
  //   // if ((event.target.scrollHeight - event.target.offsetHeight) <= event.target.scrollTop) {
  //   //   if (this.selectedUserGroupSkipToken == "" || this.state.isSelectedUserGroupsLoader) return;
  //   //   //this.getSelectedUserGroups();
  //   // }
  // }

  private onSelectFolder(folder: any, index: number): void {
    if (folder.childFolderCount == 0) return;
    this.state.channelContents[index].isOpened = (this.state.channelContents[index].isOpened == undefined) ? true : !this.state.channelContents[index].isOpened;
    if (this.state.channelContents[index].contents != undefined && this.state.channelContents[index].contents.length > 0) {
      this.setState({ channelContents: this.state.channelContents })
      return;
    }
    this.state.channelContents[index].isLoading = true
    this.setState({ channelContents: this.state.channelContents })
    MSGraphService.getFolderContents({ teamId: this.selectedTeam.id, userId: this.state.selectedUser.id, channelId: this.selectedChannel.id, folderId: folder.id, channelType: this.selectedChannel.membershipType, userType: this.state.selectedUser.userType.toLowerCase(), userMail: this.state.selectedUser.mail }).then((resData) => {
      this.state.channelContents[index].isLoading = false;
      if (resData.status === "success") {
        this.state.channelContents[index].contents = resData.data;
        this.setState({ channelContents: this.state.channelContents })
      }
    }).catch((error) => {
      this.state.channelContents[index].isLoading = false;
      this.setState({ channelContents: this.state.channelContents })
      // this.setState({ isSelectedUserFoldersLoader: false })
    })
  }


  public render(): React.ReactElement<any> {
    return (
      <>
        <div
          className={`tab-pane tabPane fade show active`}
          role="tabpanel"
        >
          <div className="manage-permissions ">
            <h1 className="section-title">Select User</h1>
            <div className="row">
              <div className="col-lg-3 col-md-6 ">
                <div className="accordion" id="selec-user">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="internal-user-heading">
                      <button
                        className={`accordion-button${this.state.userType === 'internal' ? '' : ' collapsed'
                          }`}
                        type="button"
                        onClick={() => {
                          this.setUserType('internal')
                        }}
                      >
                        Internal Users
                      </button>
                    </h2>
                    <div
                      id="internal-user"
                      className={`accordion-collapse collapse${this.state.userType === 'internal' ? ' show' : ''
                        }`}
                    >
                      <div className="accordion-body max-height-accordion" onScroll={this.onScrollInternalUsers.bind(this)}>
                        <ul className="list-group">
                          {this.state.internalUsersList.map((user: any) => {
                            return (
                              <li
                                className={
                                  this.state.selectedUser?.id === user.id
                                    ? 'list-group-item active'
                                    : 'list-group-item'
                                }
                                key={user.id}
                                onClick={() => {
                                  this.selectedUser(user)
                                }}
                                title={user.displayName}>
                                {user.displayName}
                              </li>
                            )
                          })}
                        </ul>
                        {(this.state.isInternalMembersLoader) ? <DataLoader className={"loader-center"} /> : ""}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="external-user-heading">
                      <button
                        className={`accordion-button${this.state.userType === 'external' ? '' : ' collapsed'
                          }`}
                        type="button"
                        onClick={() => {
                          this.setUserType('external')
                        }}
                      >
                        External Users
                      </button>
                    </h2>
                    <div
                      id="external-user"
                      className={`accordion-collapse collapse${this.state.userType === 'external' ? ' show' : ''
                        }`}
                    >
                      <div className="accordion-body max-height-accordion" onScroll={this.onScrollExternalUsers.bind(this)}>
                        {(this.state.externalUsersList.length == 0 && !this.state.isExternalMembersLoader) ? "Users not found" : ""}
                        <ul className="list-group external-users-list">
                          {this.state.externalUsersList.map((user: any) => {
                            return (
                              <li
                                className={
                                  this.state.selectedUser?.id === user.id
                                    ? 'list-group-item active'
                                    : 'list-group-item'
                                }
                                key={user.id}
                                onClick={() => {
                                  this.selectedUser(user)
                                }}
                                title={"EX - " + user.displayName}>
                                {"EX - " + user.displayName}
                              </li>
                            )
                          })}
                        </ul>
                        {(this.state.isExternalMembersLoader) ? <DataLoader /> : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(this.state.selectedUser != null) ? <div className="col-lg-3 col-md-6 ">
                <div className="card group-details">
                  <div className="card-header">
                    Groups
                  </div>
                  <div className="card-body max-height">
                    <ul className="list-group">
                      {this.state.userGroups.length > 0
                        ? this.state.userGroups.map((group: any, groupIndex: number) => {
                          return (<>{(group.title != undefined && group.title != null && group.title != '') ? <li className="list-group-item cp-normal" key={groupIndex} title={group.title}>
                            {group.title}
                          </li> : ''}</>

                          )
                        })
                        : (!this.state.isUserGroupsLoader) ? (this.state.userGroupStatusMessage != "") ? <><div className='retry'><span>{this.state.userGroupStatusMessage}</span> <span onClick={() => this.getUserGroups()}><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-160q-133 0-226.5-93.5T160-480q0-133 93.5-226.5T480-800q85 0 149 34.5T740-671v-129h60v254H546v-60h168q-38-60-97-97t-137-37q-109 0-184.5 75.5T220-480q0 109 75.5 184.5T480-220q83 0 152-47.5T728-393h62q-29 105-115 169t-195 64Z" /></svg></span></div></> : 'User ' +
                          String(this.state.selectedUser?.displayName) +
                          ' is not a member of any group.' : ''}
                    </ul>
                    {(this.state.isUserGroupsLoader) ? <DataLoader /> : ''}
                  </div>
                </div>
              </div> : ''}

              {this.state.selectedUser !== null && (
                <>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card channel-details">
                      <div className="card-header">
                        Channels
                      </div>
                      <div className="card-body max-height">
                        <ul className="list-group">
                          {this.state.channelsList.length > 0
                            ? this.state.channelsList.map((channel: any) => {
                              return (<>{(channel.displayName != undefined && channel.displayName != null && channel.displayName != '') ? <li className={(this.state.selectedChannel?.id === channel.id) ? 'list-group-item active' : 'list-group-item'} key={channel.id} onClick={() => {
                                this.onSelectChannel(channel)
                              }} title={channel.displayName}>
                                {channel.displayName}
                              </li> : ''}</>

                              )
                            })
                            : (!this.state.isChannelLoading) ? 'User ' +
                              String(this.state.selectedUser?.displayName) +
                              ' have no channels.' : ''}
                        </ul>
                        {(this.state.isChannelLoading) ? <DataLoader /> : ''}
                      </div>
                    </div>
                  </div>

                  {(this.state.channelsList.length > 0) ? <div className="col-lg-3 col-md-6 ">
                    <div className="card">
                      <div className="card-header">
                        Folders
                      </div>
                      {/* {this.state.loading && <LoadingAnimation />} */}
                      <div className="card-body max-height tree-holder">
                        <div className="treeview-box">
                          <div className="main-list">
                            {(this.state.isChannelContentLoader) ? <DataLoader /> : ''}
                            {this.state.channelContents.length > 0 ?
                              this.state.channelContents.map((folder: any, fonderIndex: number) => {
                                return (<><div className="title-box" key={folder.id} onClick={() => this.onSelectFolder(folder, fonderIndex)}>
                                  <div className={(folder.isOpened) ? 'arrow open' : 'arrow'}>
                                    {(folder.childFolderCount > 0) ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path></svg> : <div className='arrow-dummy'></div>}
                                  </div>
                                  <div className="icon">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                      viewBox="0 0 100 80"    ><g>
                                        <path fill="#F49D19" d="M88.6,10.8H44.8l-9.7-9.7H10.7C5.4,1.1,1,5.5,1,10.8v19.5h97.4v-9.7C98.4,15.2,94,10.8,88.6,10.8z" />
                                        <path fill="#FDC829" d="M88.6,10.8H10.7c-5.4,0-9.7,4.4-9.7,9.7v48.7c0,5.4,4.4,9.7,9.7,9.7h77.9c5.4,0,9.7-4.4,9.7-9.7V20.6
                        C98.4,15.2,94,10.8,88.6,10.8z"/></g>
                                    </svg>
                                  </div>
                                  <div className={(folder.isContainNonMembers) ? 'title-nonuser' : 'title'} title={folder.name}> {folder.name}</div>
                                </div>
                                  {(folder.contents != undefined && folder.contents.length > 0 && (folder.isOpened != undefined && folder.isOpened)) ? <TreeView data={folder.contents} contentType="user" team={this.selectedTeam} channel={this.selectedChannel} user={this.state.selectedUser} /> : ''}
                                  {(folder.isLoading != undefined && folder.isLoading) ? <DataLoader /> : ''}
                                </>)
                              }) :
                              (!this.state.isChannelContentLoader) ? (this.state.userFolderStatusMessage != "") ? <><div className='retry'><span>{this.state.userFolderStatusMessage}</span> <span onClick={() => this.getChannelContents()}><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-160q-133 0-226.5-93.5T160-480q0-133 93.5-226.5T480-800q85 0 149 34.5T740-671v-129h60v254H546v-60h168q-38-60-97-97t-137-37q-109 0-184.5 75.5T220-480q0 109 75.5 184.5T480-220q83 0 152-47.5T728-393h62q-29 105-115 169t-195 64Z" /></svg></span></div></> : 'User have no folder access' : ''}
                          </div>
                        </div>
                        {/* <TreeView
                                        data={this.state.newData ?? []}
                                        selMultipleGroups={this.state.selMultipleGroups}
                                    /> */}
                      </div>
                    </div>
                  </div> : ''}

                </>
              )}
            </div>
            {this.state.selectedUser !== null && false && (
              <div className="fme-btn-group">
                <button type="button" className="btn black btn-fme me-auto">
                  Cancel
                </button>
                <button type="button" className="btn outline btn-fme">
                  Reset
                </button>
                <button type="button" className="btn blue btn-fme">
                  Save
                </button>
              </div>
            )}
          </div>
        </div >
      </>
    )
  }
}

// function InternalUsersComponent({ data, height }: { data: string, height: number }): JSX.Element {
//     const [name, SetName] = useState<string>('Siva R');
//     useEffect(() => {
//         SetName(data);
//     }, [data])
//     return (<>Internal Users{name}</>);
// }

// export default InternalUsersComponent
