import axios from "axios";
import { Global } from './Global'

export class MSGraphService {
    // public static APIURL = process.env.REACT_APP_MSGRAPH_SERVICE_URL
    public static APIURL = process.env.REACT_APP_SERVICE_URL;
    private static requestUserInfo: any = null;
    private static requestAccessToken: any = null;
    private static requestUserTeams: any = null;
    private static requestChannelCount: any = null;
    private static requestOwners: any = null;
    private static requestInternalMembers: any = null;
    private static requestExternalMembers: any = null;
    private static requestChannels: any = null;
    public static requestSharePointGroups: any = null;
    public static requestSharePointGroupsMembers: any = null;
    public static requestGroupRootContents: any = null;
    public static requestGroupFolderContents: any = null;
    public static requestSharePointSites: any = null;
    public static requestAddGroup: any = null;
    public static requestUserGroups: any = null;
    private static requestUserContents: any = null;
    private static requestTeamChannels: any = null;
    private static requestFolderContents: any = null;
    private static requestFolderAccessedList: any = null;
    private static requestBreakInheritance: any = null;
    private static requestRemoveAccess: any = null;
    private static requestUpdateAccess: any = null;
    private static requestAccessGroups: any = null;
    private static requestSearchTeamGroups: any = null;
    private static requestSearchTeamMembers: any = null;

    public static async getAccessToken(data: any = {}): Promise<any> {
        if (this.requestAccessToken !== null) {
            console.log("getAccessToken already requested")
            return;
        }
        let url = `${this.APIURL}/api/user/token`
        if (data)
            this.requestAccessToken = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestAccessToken.token,
            headers: {
                Authorization: data.idToken
            }
        }).then((resData: any) => {
            this.requestAccessToken = null;
            if (resData.status === 200) {
                return { status: "success", accessData: resData.data.accessData, };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestAccessToken = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getCurrentUserInfo(): Promise<any> {
        const url = String(this.APIURL) + "/api/user"
        this.requestUserInfo = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestUserInfo.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            if (resData.status === 200) {
                return { status: "success", data: resData.data.data, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            if (error?.response?.status === 401) {
                return { status: "failed", message: "You don't have access for this application." };
            } else {
                return { status: "failed", message: error.message };
            }
        })
        return response;
    }

    public static async getUserTeams(data: any = {}): Promise<any> {
        if (this.requestUserTeams !== null) {
            this.requestUserTeams.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/user/teams?skipToken=${data.skipToken}&search=${data.searchString}`
        this.requestUserTeams = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestUserTeams.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            if (resData.status === 200) {
                return { status: "success", data: resData.data.data, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getChannelsCount(data: any = {}): Promise<any> {
        if (this.requestChannelCount !== null) {
            this.requestChannelCount.cancel('Request canceled by user.')
        }
        const url = String(this.APIURL) + "/api/teams/" + String(data.teamId) + "/channels/count"
        this.requestChannelCount = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestChannelCount.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestChannelCount = null;
            if (resData.status === 200) {
                return { status: "success", standardChannels: resData.data.standardChannels, privateChannels: resData.data.privateChannels, sharedChannels: resData.data.sharedChannels, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestChannelCount = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getOwners(data: any = {}): Promise<any> {
        if (this.requestOwners !== null) {
            this.requestOwners.cancel('Request canceled by user.')
        }
        const url = String(this.APIURL) + "/api/teams/" + String(data.teamId) + "/owners"
        this.requestOwners = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestOwners.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestOwners = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.owners, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestOwners = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getTeamInternalMembers(data: any = {}): Promise<any> {
        if (this.requestInternalMembers !== null) {
            this.requestInternalMembers.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/teams/${data.teamId}/internal/members`
        if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
        this.requestInternalMembers = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestInternalMembers.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestInternalMembers = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.members, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestInternalMembers = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getTeamExternalMembers(data: any = {}): Promise<any> {
        if (this.requestExternalMembers !== null) {
            this.requestExternalMembers.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/teams/${data.teamId}/external/members`
        if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
        this.requestExternalMembers = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestExternalMembers.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestExternalMembers = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.members, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestExternalMembers = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getUserChannels(data: any = {}): Promise<any> {
        if (this.requestChannels !== null) {
            this.requestChannels.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/user/${data.userId}/channels/${data.teamId}`
        if (data)
            this.requestChannels = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestChannels.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestChannels = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.channels, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestChannels = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getSharePointGroupsByUser(data: any = {}): Promise<any> {
        if (this.requestSharePointGroups !== null) {
            this.requestSharePointGroups.cancel('Request canceled by user.')
        }
        // let url = `${this.APIURL}/api/user/${data.userId}/${data.teamId}/${data.channelId}/groups`
        let url = `${this.APIURL}/api/user/${data.userId}/${Global.encryptData(data.userEmail)}/${data.teamId}/groups`
        if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
        if (data)
            this.requestSharePointGroups = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestSharePointGroups.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestSharePointGroups = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.groups, skipToken: "", message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getSharePointGroupMembers(data: any = {}, teamId: string): Promise<any> {
        if (this.requestSharePointGroupsMembers !== null) {
            this.requestSharePointGroupsMembers.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${teamId}/group/members`;
        if (data)
            this.requestSharePointGroupsMembers = axios.CancelToken.source()
        let sendData = JSON.stringify(data);
        const response = await axios.post(encodeURI(url), sendData, {
            cancelToken: this.requestSharePointGroupsMembers.token,
            headers: {
                "Authorization": Global.getAccessToken(),
                "Content-Type": "application/json"
            }
        }).then((resData: any) => {
            this.requestSharePointGroupsMembers = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.members, skipToken: "", message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getGroupRootContents(data: any = {}, teamId: string): Promise<any> {
        if (this.requestGroupRootContents !== null) {
            this.requestGroupRootContents.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${teamId}/group/channels`;
        if (data)
            this.requestGroupRootContents = axios.CancelToken.source()
        let sendData = JSON.stringify(data);
        const response = await axios.post(encodeURI(url), sendData, {
            cancelToken: this.requestGroupRootContents.token,
            headers: {
                "Authorization": Global.getAccessToken(),
                "Content-Type": "application/json"
            }
        }).then((resData: any) => {
            this.requestGroupRootContents = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.channels, skipToken: "", message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getGroupContents(data: any = {}): Promise<any> {
        if (this.requestGroupFolderContents !== null) {
            this.requestGroupFolderContents.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${data.teamId}/group/contents`;
        if (data)
            this.requestGroupFolderContents = axios.CancelToken.source()
        let sendData = JSON.stringify(data);
        const response = await axios.post(encodeURI(url), sendData, {
            cancelToken: this.requestGroupFolderContents.token,
            headers: {
                "Authorization": Global.getAccessToken(),
                "Content-Type": "application/json"
            }
        }).then((resData: any) => {
            this.requestGroupFolderContents = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.contents, skipToken: "", message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getSharePointSites(data: any): Promise<any> {
        if (this.requestSharePointSites != null) {
            this.requestSharePointSites.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${data.teamId}/sites`;
        if (data)
            this.requestSharePointSites = axios.CancelToken.source()
        let sendData = JSON.stringify(data);
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestSharePointSites.token,
            headers: {
                "Authorization": Global.getAccessToken(),
                "Content-Type": "application/json"
            }
        }).then((resData: any) => {
            this.requestSharePointSites = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.sites };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async addGroup(data: any = {}): Promise<any> {
        if (this.requestAddGroup !== null) {
            this.requestAddGroup.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${data.teamId}/group`;
        if (data)
            this.requestAddGroup = axios.CancelToken.source()
        let sendData = JSON.stringify(data);
        const response = await axios.post(encodeURI(url), sendData, {
            cancelToken: this.requestAddGroup.token,
            headers: {
                "Authorization": Global.getAccessToken(),
                "Content-Type": "application/json"
            }
        }).then((resData: any) => {
            this.requestAddGroup = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.group };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getTeamMembers(data: any = {}): Promise<any> {
        if (this.requestSearchTeamMembers !== null) {
            this.requestSearchTeamMembers.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${data.teamId}/members`
        let sendData: any = { searchString: data.searchString }
        if (data)
            this.requestSearchTeamMembers = axios.CancelToken.source()
        const response = await axios.post(encodeURI(url), sendData, {
            cancelToken: this.requestSearchTeamMembers.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestSearchTeamMembers = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.members, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestSearchTeamMembers = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async addGroupMember(data: any = {}): Promise<any> {
        if (this.requestAddGroup !== null) {
            this.requestAddGroup.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${data.teamId}/member/add`;
        if (data)
            this.requestAddGroup = axios.CancelToken.source()
        let sendData = JSON.stringify(data.groupInfo);
        const response = await axios.post(encodeURI(url), sendData, {
            cancelToken: this.requestAddGroup.token,
            headers: {
                "Authorization": Global.getAccessToken(),
                "Content-Type": "application/json"
            }
        }).then((resData: any) => {
            this.requestAddGroup = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.member };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async removeGroupMember(data: any = {}): Promise<any> {
        if (this.requestAddGroup !== null) {
            this.requestAddGroup.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${data.teamId}/member/remove`;
        if (data)
            this.requestAddGroup = axios.CancelToken.source()
        let sendData = JSON.stringify(data.userInfo);
        const response = await axios.post(encodeURI(url), sendData, {
            cancelToken: this.requestAddGroup.token,
            headers: {
                "Authorization": Global.getAccessToken(),
                "Content-Type": "application/json"
            }
        }).then((resData: any) => {
            this.requestAddGroup = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.member };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            console.log(error);
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }



    public static async getUserGroups(data: any = {}): Promise<any> {
        if (this.requestUserGroups !== null) {
            this.requestUserGroups.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/user/${data.userId}/memberof`
        if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
        if (data)
            this.requestUserGroups = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestUserGroups.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestUserGroups = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.memberOf, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestUserGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }


    public static async getTeamAccessGroups(data: any = {}): Promise<any> {
        if (this.requestAccessGroups !== null) {
            this.requestAccessGroups.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${data.teamId}/groups`
        if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
        if (data)
            this.requestAccessGroups = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestAccessGroups.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestAccessGroups = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.groups, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestAccessGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async searchTeamGroups(data: any = {}): Promise<any> {
        if (this.requestSearchTeamGroups !== null) {
            this.requestSearchTeamGroups.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/${data.teamId}/groups`
        if (data) this.requestSearchTeamGroups = axios.CancelToken.source()
        const response = await axios.post(encodeURI(url), data, {
            cancelToken: this.requestSearchTeamGroups.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestSearchTeamGroups = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.groups, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestSearchTeamGroups = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }


    public static async getChannelContents(data: any = {}): Promise<any> {
        if (this.requestUserContents !== null) {
            this.requestUserContents.cancel('Request canceled by user.')
        }
        if (data.userType != "guest") {
            let url = `${this.APIURL}/api/user/${data.userId}/${data.teamId}/${data.channelId}/${data.folderId}`
            if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
            if (data)
                this.requestUserContents = axios.CancelToken.source()
            const response = await axios.get(encodeURI(url), {
                cancelToken: this.requestUserContents.token,
                headers: {
                    Authorization: Global.getAccessToken()
                }
            }).then((resData: any) => {
                this.requestUserContents = null;
                if (resData.status === 200) {
                    return { status: "success", data: resData.data.contents, skipToken: resData.data.skipToken, message: "" };
                } else {
                    return { status: "failed", message: "" };
                }
            }).catch((error: any) => {
                this.requestUserContents = null;
                return { status: "failed", message: error.message };
            })
            return response;
        } else {
            let url = `${this.APIURL}/api/guest/channel/contents`
            if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
            if (data)
                this.requestUserContents = axios.CancelToken.source()
            const response = await axios.post(encodeURI(url), { userId: data.userId, email: data.userMail, teamId: data.teamId, channelId: data.channelId, channelType: data.channelType, folderId: "" }, {
                cancelToken: this.requestUserContents.token,
                headers: {
                    Authorization: Global.getAccessToken()
                }
            }).then((resData: any) => {
                this.requestUserContents = null;
                if (resData.status === 200) {
                    return { status: "success", data: resData.data.contents, skipToken: resData.data.skipToken, message: "" };
                } else {
                    return { status: "failed", message: "" };
                }
            }).catch((error: any) => {
                this.requestUserContents = null;
                return { status: "failed", message: error.message };
            })
            return response;
        }


    }

    public static async getFolderContents(data: any = {}): Promise<any> {
        if (this.requestFolderContents !== null) {
            this.requestFolderContents.cancel('Request canceled by user.')
        }
        console.log(data);
        if (data.userType != "guest") {
            let url = `${this.APIURL}/api/user/${data.userId}/${data.teamId}/${data.channelId}/${data.folderId}`
            if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
            if (data)
                this.requestFolderContents = axios.CancelToken.source()
            const response = await axios.get(encodeURI(url), {
                cancelToken: this.requestFolderContents.token,
                headers: {
                    Authorization: Global.getAccessToken()
                }
            }).then((resData: any) => {
                this.requestFolderContents = null;
                if (resData.status === 200) {
                    return { status: "success", data: resData.data.contents, skipToken: resData.data.skipToken, message: "" };
                } else {
                    return { status: "failed", message: "" };
                }
            }).catch((error: any) => {
                this.requestFolderContents = null;
                return { status: "failed", message: error.message };
            })
            return response;
        } else {
            let url = `${this.APIURL}/api/guest/channel/contents`
            if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
            if (data)
                this.requestUserContents = axios.CancelToken.source()
            const response = await axios.post(encodeURI(url), { userId: data.userId, email: data.userMail, teamId: data.teamId, channelId: data.channelId, channelType: data.channelType, folderId: data.folderId }, {
                cancelToken: this.requestUserContents.token,
                headers: {
                    Authorization: Global.getAccessToken()
                }
            }).then((resData: any) => {
                this.requestUserContents = null;
                if (resData.status === 200) {
                    return { status: "success", data: resData.data.contents, skipToken: resData.data.skipToken, message: "" };
                } else {
                    return { status: "failed", message: "" };
                }
            }).catch((error: any) => {
                this.requestUserContents = null;
                return { status: "failed", message: error.message };
            })
            return response;
        }

    }


    // public static async getUserRootFolders(data: any = {}): Promise<any> {
    //     if (this.requestUserRootFolders !== null) {
    //         this.requestUserRootFolders.cancel('Request canceled by user.')
    //     }
    //     let url = `${this.APIURL}/api/teams/${data.teamId}/${data.userId}/rootcontents`
    //     if (data.skipToken != undefined && data.skipToken != null && data.skipToken != "") url += `?skipToken=${data.skipToken}`;
    //     if (data)
    //         this.requestUserRootFolders = axios.CancelToken.source()
    //     const response = await axios.get(encodeURI(url), {
    //         cancelToken: this.requestUserRootFolders.token,
    //         headers: {
    //             Authorization: Global.getAccessToken()
    //         }
    //     }).then((resData) => {
    //         this.requestUserRootFolders = null;
    //         if (resData.status === 200) {
    //             return { status: "success", data: resData.data.channels, skipToken: resData.data.skipToken, message: "" };
    //         } else {
    //             return { status: "failed", message: "" };
    //         }
    //     }).catch((error) => {
    //         this.requestUserRootFolders = null;
    //         return { status: "failed", message: error.message };
    //     })
    //     return response;
    // }

    public static async getTeamChannels(data: any = {}): Promise<any> {
        if (this.requestTeamChannels !== null) {
            this.requestTeamChannels.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/teams/${data.teamId}/channels`
        this.requestTeamChannels = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestTeamChannels.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestTeamChannels = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.channels, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestTeamChannels = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getTeamChannelsInFolders(data: any = {}): Promise<any> {
        if (this.requestTeamChannels !== null) {
            this.requestTeamChannels.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/teams/${data.teamId}/folders`
        this.requestTeamChannels = axios.CancelToken.source()
        const response = await axios.get(encodeURI(url), {
            cancelToken: this.requestTeamChannels.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestTeamChannels = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.channels, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestTeamChannels = null;
            let errorMessage = "";
            if (error.data?.message != undefined) errorMessage = error.data?.message;
            if (errorMessage.indexOf("The SSL connection could not be established") >= 0) {
                return { status: "failed", message: errorMessage };
            } else {
                return { status: "failed", message: error.message };
            }

        })
        return response;
    }

    public static async getTeamFolderContents(data: any = {}): Promise<any> {
        // if (this.requestFolderContents !== null) {
        //     this.requestFolderContents.cancel('Request canceled by user.')
        // }
        let url = `${this.APIURL}/api/team/folder/contents`
        // if (data) 
        // this.requestFolderContents = axios.CancelToken.source()
        const response = await axios.post(encodeURI(url), data, {
            // cancelToken: this.requestFolderContents.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            // this.requestFolderContents = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.contents, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            // this.requestFolderContents = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async getAccessedList(data: any = {}): Promise<any> {
        // if (this.requestFolderAccessedList !== null) {
        //     this.requestFolderAccessedList.cancel('Request canceled by user.')
        // }
        let url = `${this.APIURL}/api/team/folder/access`
        if (data)
            this.requestFolderAccessedList = axios.CancelToken.source()
        const response = await axios.post(encodeURI(url), data, {
            // cancelToken: this.requestFolderAccessedList.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            // this.requestFolderAccessedList = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.accessList, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            // this.requestFolderAccessedList = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async updateInheritanceStatus(data: any = {}): Promise<any> {
        if (this.requestBreakInheritance !== null) {
            this.requestBreakInheritance.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/folder/inheritance`
        if (data)
            this.requestBreakInheritance = axios.CancelToken.source()
        const response = await axios.post(encodeURI(url), data, {
            cancelToken: this.requestBreakInheritance.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestBreakInheritance = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.accessList, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestBreakInheritance = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async checkInheritStatus(data: any = {}): Promise<any> {
        if (this.requestBreakInheritance !== null) {
            this.requestBreakInheritance.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/folder/inheritance/status`
        if (data)
            this.requestBreakInheritance = axios.CancelToken.source()
        const response = await axios.post(encodeURI(url), data, {
            cancelToken: this.requestBreakInheritance.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestBreakInheritance = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.isInherit, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestBreakInheritance = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async removeAccess(data: any = {}): Promise<any> {
        if (this.requestRemoveAccess !== null) {
            this.requestRemoveAccess.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/folder/access/remove`
        if (data)
            this.requestRemoveAccess = axios.CancelToken.source()
        const response = await axios.post(encodeURI(url), data, {
            cancelToken: this.requestRemoveAccess.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestRemoveAccess = null;
            if (resData.status === 200) {
                return { status: "success", data: resData.data.accessList, skipToken: resData.data.skipToken, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestRemoveAccess = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }

    public static async updateAccess(data: any = {}): Promise<any> {
        if (this.requestUpdateAccess !== null) {
            this.requestUpdateAccess.cancel('Request canceled by user.')
        }
        let url = `${this.APIURL}/api/team/folder/access/update`
        if (data)
            this.requestUpdateAccess = axios.CancelToken.source()
        const response = await axios.post(encodeURI(url), data, {
            cancelToken: this.requestUpdateAccess.token,
            headers: {
                Authorization: Global.getAccessToken()
            }
        }).then((resData: any) => {
            this.requestUpdateAccess = null;
            if (resData.status === 200) {
                return { status: "success", data: null, message: "" };
            } else {
                return { status: "failed", message: "" };
            }
        }).catch((error: any) => {
            this.requestUpdateAccess = null;
            return { status: "failed", message: error.message };
        })
        return response;
    }





    // public static async getStandardChannelsCount(data: any = {}): Promise<any> {
    //     if (this.requestStandardChannelCount !== null) {
    //         this.requestStandardChannelCount.cancel('Request canceled by user.')
    //     }
    //     let currentUser = Global.getCurrentUser();
    //     const url = String(this.APIURL) + "/teams/" + String(data.teamId) + "/channels?$filter=membershipType eq 'standard'"
    //     this.requestStandardChannelCount = axios.CancelToken.source()
    //     const response = await axios.get(encodeURI(url), {
    //         cancelToken: this.requestStandardChannelCount.token,
    //         headers: {
    //             Authorization: Global.getAccessToken()
    //         }
    //     }).then((resData) => {
    //         this.requestStandardChannelCount = null;
    //         if (resData.status === 200) {
    //             return { status: "success", data: resData.data, count: resData.data['@odata.count'], message: "" };
    //         } else {
    //             return { status: "failed", message: "" };
    //         }
    //     }).catch((error) => {
    //         this.requestStandardChannelCount = null;
    //         return { status: "failed", message: error.message };
    //     })
    //     return response;
    // }

    // public static async getPrivateChannelsCount(data: any = {}): Promise<any> {
    //     if (this.requestPrivateChannelCount !== null) {
    //         this.requestPrivateChannelCount.cancel('Request canceled by user.')
    //     }
    //     const url = String(this.APIURL) + "/teams/" + String(data.teamId) + "/channels?$filter=membershipType eq 'private'"
    //     this.requestPrivateChannelCount = axios.CancelToken.source()
    //     const response = await axios.get(encodeURI(url), {
    //         cancelToken: this.requestPrivateChannelCount.token,
    //         headers: {
    //             Authorization: Global.getAccessToken()
    //         }
    //     }).then((resData) => {
    //         this.requestPrivateChannelCount = null;
    //         if (resData.status === 200) {
    //             return { status: "success", data: resData.data, count: resData.data['@odata.count'], message: "" };
    //         } else {
    //             return { status: "failed", message: "" };
    //         }
    //     }).catch((error) => {
    //         this.requestPrivateChannelCount = null;
    //         return { status: "failed", message: error.message };
    //     })
    //     return response;
    // }

    // public static async getSharedChannelsCount(data: any = {}): Promise<any> {
    //     if (this.requestSharedChannelCount !== null) {
    //         this.requestSharedChannelCount.cancel('Request canceled by user.')
    //     }
    //     const url = String(this.APIURL) + "/teams/" + String(data.teamId) + "/channels?$filter=membershipType eq 'shared'"
    //     this.requestSharedChannelCount = axios.CancelToken.source()
    //     const response = await axios.get(encodeURI(url), {
    //         cancelToken: this.requestSharedChannelCount.token,
    //         headers: {
    //             Authorization: Global.getAccessToken()
    //         }
    //     }).then((resData) => {
    //         this.requestSharedChannelCount = null;
    //         if (resData.status === 200) {
    //             return { status: "success", data: resData.data, count: resData.data['@odata.count'], message: "" };
    //         } else {
    //             return { status: "failed", message: "" };
    //         }
    //     }).catch((error) => {
    //         this.requestSharedChannelCount = null;
    //         return { status: "failed", message: error.message };
    //     })
    //     return response;
    // }
}
