import * as React from "react";

export default class MPTabMenusComponent extends React.Component<any, any> {
  public constructor(props: any, state: any) {
    super(props);
    this.state = {
      selectedTab: "user_access",
    };
  }

  private onSelectTab(tabName: string): void {
    this.setState({ selectedTab: tabName });
    this.props.onSelectTab(tabName);
  }

  public render(): React.ReactElement<any> {
    return (
      <>
        <nav className="nav nav-tabs fme-tab-nav" role="tablist">
          <a
            className={
              this.state.selectedTab === "user_access"
                ? "nav-link active"
                : "nav-link"
            }
            onClick={() => {
              this.onSelectTab("user_access");
            }}
            role="tab"
            aria-selected="false"
          >
            Users Access{" "}
          </a>
          <a
            className={
              this.state.selectedTab === "groups"
                ? "nav-link active"
                : "nav-link"
            }
            onClick={() => {
              this.onSelectTab("groups");
            }}
            role="tab"
            aria-selected="false"
          >
            Groups{" "}
          </a>
          <a
            className={
              this.state.selectedTab === "folders"
                ? "nav-link active"
                : "nav-link"
            }
            onClick={() => {
              this.onSelectTab("folders");
            }}
            role="tab"
            aria-selected="true"
          >
            Folders
          </a>
        </nav>
      </>
    );
  }
}
