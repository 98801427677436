import React from 'react'
import './TreeView.scss'
import { Global } from '../../services/Global';
import { MSGraphService } from '../../services/MSGraphService';
import DataLoader from '../DataLoader/DataLoader';
export default class TreeView extends React.Component<any, any>{
    private contentSkipToken: string = "";

    public constructor(props: any, state: any) {
        super(props)
        this.state = {
            contents: this.props.data
        }
    }
    componentDidMount(): void {

    }

    componentWillUnmount(): void {

    }

    private onSelectFolder(folder: any, index: number) {
        if (folder.childFolderCount == 0) return;
        this.state.contents[index].isOpened = (this.state.contents[index].isOpened == undefined) ? true : !this.state.contents[index].isOpened;
        if (this.state.contents[index].contents != undefined && this.state.contents[index].contents.length > 0) {
            this.setState({ contents: this.state.contents })
            return;
        }
        this.state.contents[index].isLoading = true
        this.setState({ contents: this.state.contents })
        if (this.props.contentType == "group") {
            let sendData = { teamId: this.props.team.id, groupId: this.props.group.id, groupName: this.props.group.loginName, channelId: this.props.channel.id, channelType: "", folderId: folder.id }
            MSGraphService.getGroupContents(sendData).then((resData) => {
                if (resData.status === "success") {
                    this.state.contents[index].isLoading = false;
                    this.state.contents[index].contents = resData.data;
                    this.setState({ contents: this.state.contents })
                } else {
                    this.state.contents[index].isLoading = false;
                    this.setState({ contents: this.state.contents })
                    // if (resData.message != "Request canceled by user.") {
                    //     this.setState({ contents: this.state.contents })
                    // } else {
                    //     this.setState({ contents: this.state.contents })
                    // }
                }
            }).catch((error) => {
                this.state.groupContents[index].isLoading = false;
                this.setState({ isGroupContentParentLoader: false })
            })
        } else {
            MSGraphService.getFolderContents({ teamId: this.props.team.id, userId: this.props.user.id, channelId: this.props.channel.id, folderId: folder.id, skipToken: this.contentSkipToken }).then((resData) => {
                this.state.contents[index].isLoading = false;
                if (resData.status === "success") {
                    if (this.contentSkipToken != undefined && this.contentSkipToken != null && this.contentSkipToken != "") {
                        this.state.contents[index].contents = resData.data;
                        this.setState({ contents: this.state.contents })
                    } else {
                        this.state.contents[index].contents = resData.data;
                        this.setState({ contents: this.state.contents })
                    }
                    this.contentSkipToken = resData.skipToken;
                }
            }).catch((error) => {
                this.contentSkipToken = "";
                this.state.contents[index].isLoading = false;
                this.setState({ contents: this.state.contents })
            })
        }
    }

    public render(): React.ReactElement<any> {
        return (
            <>{this.state.contents.map((folder: any, folderIndex: number) => {
                return <div className="sub-folder" key={folderIndex}>
                    <div className="title-box" onClick={() => { this.onSelectFolder(folder, folderIndex) }}>
                        {(folder.childFolderCount > 0) ? <div className={(folder.isOpened) ? 'arrow open' : 'arrow'}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path></svg>
                        </div> : <div className='arrow-dummy'></div>}
                        <div className="icon">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 100 80"    ><g>
                                    <path fill="#F49D19" d="M88.6,10.8H44.8l-9.7-9.7H10.7C5.4,1.1,1,5.5,1,10.8v19.5h97.4v-9.7C98.4,15.2,94,10.8,88.6,10.8z" />
                                    <path fill="#FDC829" d="M88.6,10.8H10.7c-5.4,0-9.7,4.4-9.7,9.7v48.7c0,5.4,4.4,9.7,9.7,9.7h77.9c5.4,0,9.7-4.4,9.7-9.7V20.6
                C98.4,15.2,94,10.8,88.6,10.8z"/></g>
                            </svg>
                        </div>
                        <div className="title" title={folder.name}>{folder.name}</div>
                    </div>
                    {(folder.contents != undefined && folder.contents.length > 0 && (folder.isOpened != undefined && folder.isOpened)) ? <TreeView data={folder.contents} contentType={this.props.contentType} group={this.props.group} team={this.props.team} channel={this.props.channel} user={this.props.user} /> : ''}
                    {(folder.isLoading != undefined && folder.isLoading) ? <DataLoader /> : ''}
                </div>
            })}</>
        );
    }
}