import React from 'react'
import './GroupUserPicker.scss'
import { MSGraphService } from '../../services/MSGraphService'
import DataLoader from '../DataLoader/DataLoader';
import { Global } from '../../services/Global';
export default class GroupUserPicker extends React.Component<any, any>{
    private outsideEvent: any = null;
    private searchString: string = "";
    public constructor(props: any, state: any) {
        super(props)
        this.state = {
            listTypeSelector: false,
            selectedListType: "group",
            searchString: "",
            sourceList: [],
            isGroupSearchProcessing: false,
            isUserSearchProcessing: false
        }
    }

    componentDidMount(): void {
        this.outsideEvent = Global.onGUPickerOutsideEvent.subscribe((data: any) => {
            if (data != undefined && data != null && data != "") {
                if (data.indexOf("gu-picker-list-item") < 0) {
                    this.onSelectListType(this.state.selectedListType)
                }
            } else {
                this.onSelectListType(this.state.selectedListType)
            }
        })
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount(): void {
        Global.onGUPickerOutsideEvent.unSubscribe(this.outsideEvent);
    }

    public handleClickOutside(event: any) {
        try {
            if (event.target.className.indexOf("gu-picker-input") < 0 || event.target.className.indexOf("gu-picker-list-item") < 0) {
                Global.onGUPickerOutsideEvent.emit(event.target.className);
            }
        } catch {
            Global.onGUPickerOutsideEvent.emit(event.target.className);
        }
    }


    private onSelectListType(listType: string) {
        this.searchString = "";
        this.setState({ selectedListType: listType, searchString: this.searchString, sourceList: [] })
    }

    private onChangeSearchText(event: any) {
        this.searchString = event.target.value;
        if (this.searchString != "") {
            this.setState({ searchString: this.searchString })
            if (this.state.selectedListType == "group") {
                this.getGroupsList();
            } else {
                this.getUsersList();
            }
        } else {
            this.setState({ searchString: this.searchString, sourceList: [] })
        }

    }

    private getGroupsList() {
        this.setState({ isGroupSearchProcessing: true, sourceList: [] })
        MSGraphService.searchTeamGroups({ teamId: this.props.team.id, searchString: this.searchString, siteReference: this.props.folder.spFolderInfo }).then((resData) => {
            if (resData.status === "success") {
                this.setState({ isGroupSearchProcessing: false, sourceList: resData.data })
            } else {
                this.setState({ isGroupSearchProcessing: false })
            }
        }).catch((error) => {
            this.setState({ isGroupSearchProcessing: false })
        })

    }

    private getUsersList() {
        this.setState({ isUserSearchProcessing: true, sourceList: [] })
        MSGraphService.getTeamMembers({ teamId: this.props.team.id, searchString: this.searchString }).then((resData) => {
            if (resData.status === "success") {
                this.setState({ isUserSearchProcessing: false, sourceList: resData.data })
            } else {
                this.setState({ isUserSearchProcessing: false })
            }
        }).catch((error) => {
            this.setState({ isUserSearchProcessing: false })
        })
    }

    private onSelectListItem(selectedItem: any) {
        let outData = null
        if (this.state.selectedListType == "group") {
            outData = { accessType: this.state.selectedListType, email: "", groupId: selectedItem.id + "", folderInfo: this.props.folder.spFolderInfo, driveInfo: this.props.folder.driveInfo }
        } else {
            outData = { accessType: this.state.selectedListType, email: selectedItem.mail, groupId: "", folderInfo: this.props.folder.spFolderInfo, driveInfo: this.props.folder.driveInfo }
        }
        this.searchString = "";
        this.setState({ searchString: "", sourceList: [] })
        this.props.onSelect(outData);
    }


    public render(): React.ReactElement<any> {
        return (<>
            <div className="gu-picker" >
                <div className='gu-picker-container'>
                    <div className={(this.state.listTypeSelector) ? 'gu-picker-header' : 'gu-picker-header no-display'}>
                        <button className={(this.state.selectedListType == 'group') ? "icon-group active" : "icon-group"} onClick={() => this.onSelectListType('group')} disabled={this.props.disabled}>Group</button>
                        <button className={(this.state.selectedListType == 'user') ? "icon-user active" : "icon-user"} onClick={() => this.onSelectListType('user')} disabled={this.props.disabled}>User</button>
                    </div>
                    <div className='gu-picker-body'>
                        <div className='input-box'>
                            <input className='gu-picker-input' type='search' value={this.state.searchString} onChange={(event) => this.onChangeSearchText(event)} placeholder={(this.state.selectedListType == 'group') ? "Search group" : "Search user"} disabled={this.props.disabled} onFocus={() => this.setState({ listTypeSelector: true })} />
                            {(this.state.isGroupSearchProcessing || this.state.isUserSearchProcessing) ? <DataLoader /> : ""}
                        </div>
                        <div className='gu-picker-list-container'>{this.state.sourceList.map((source: any, sIndex: number) => {
                            if (this.state.selectedListType == 'group') {
                                return <div key={sIndex} className='gu-picker-list-item' onClick={() => this.onSelectListItem(source)} title={source.title}>{source.title}</div>
                            } else {
                                return <div key={sIndex} className='gu-picker-list-item' onClick={() => this.onSelectListItem(source)} title={source.displayName}>{source.displayName}</div>
                            }

                        })}
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}