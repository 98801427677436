import * as React from "react";

export default class USTabMenusComponent extends React.Component<any, any> {
  public constructor(props: any, state: any) {
    super(props);
    this.state = {
      selectedTab: "overview",
    };
  }

  private onSelectTab(tabName: string): void {
    this.setState({ selectedTab: tabName });
    this.props.onSelectTab(tabName);
  }

  public render(): React.ReactElement<any> {
    return (
      <>
        <nav className="nav nav-tabs fme-tab-nav" role="tablist">
          <a
            className={
              this.state.selectedTab === "overview"
                ? "nav-link active"
                : "nav-link"
            }
            onClick={() => {
              this.onSelectTab("overview");
            }}
            role="tab"
            aria-selected="false"
          >
            Usage Overview
          </a>
          {/* <a
            className={
              this.state.selectedTab === "activity"
                ? "nav-link active"
                : "nav-link"
            }
            onClick={() => {
              this.onSelectTab("activity");
            }}
            role="tab"
            aria-selected="false"
          >
            User Activity
          </a> */}
        </nav>
      </>
    );
  }
}
