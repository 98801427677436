import * as React from 'react'
import './MPFolders.scss'
import DataLoader from '../../../components/DataLoader/DataLoader'
import { Global } from '../../../services/Global';
import TreeView from '../../../components/TreeView/TreeView';
import { MSGraphService } from '../../../services/MSGraphService';
import TreeViewTable from '../../../components/TreeViewTable/TreeViewTable';
import { Modal } from 'react-bootstrap';
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GroupUserPicker from '../../../components/GroupUserPicker/GroupUserPicker';
export default class MPFoldersComponent extends React.Component<any, any> {

  private selectedTeam: any = null;
  private teamEmitterStatus: any = null;
  private selectedChannel: any = null;

  public constructor(props: any, state: any) {
    super(props)
    this.state = {
      selectedTeam: null,
      isChannelsLoader: true,
      teamContents: [],
      channelStatusMessage: "",
      showInhertConfirmation: false,
      inhertConfirmationMessage: "",
      selectedFolderInfo: null,
      isInhertStatusUpdateProcessing: false,
      showRemoveAccessConfirmation: false,
      removeAccessConfirmationMessage: "",
      selectedAccessUserIndex: null,
      isProcessingRemoveAccess: false,
      isProcessingAddAccess: false,
    }
  }

  componentDidMount(): void {
    this.selectedTeam = Global.getSelectedTeam();
    if (this.selectedTeam !== null) {
      this.setState({ selectedTeam: this.selectedTeam })
      this.getTeamChannels();
    }
    this.teamEmitterStatus = Global.onTeamSelected.subscribe((data: any) => {
      this.selectedTeam = data;
      this.setState({ selectedTeam: data, isChannelsLoader: true, teamContents: [] })
      this.getTeamChannels();
    })
  }

  componentWillUnmount(): void {
    Global.onTeamSelected.unSubscribe(this.teamEmitterStatus);
  }

  public getTeamChannels(): void {
    this.setState({ isChannelsLoader: true, teamContents: [], channelStatusMessage: "" })
    MSGraphService.getTeamChannelsInFolders({ teamId: this.selectedTeam.id }).then((resData: any) => {
      if (resData.status == "success") {
        this.setState({ isChannelsLoader: false });
        resData.data.forEach((currentFolder: any, CFindex: number) => {
          currentFolder.isInheritStatusLoader = false;
          currentFolder.inheritStatusCheckCount = 0
          this.state.teamContents.push(currentFolder);
          this.setState({ teamContents: this.state.teamContents })
          if (currentFolder.isInheritApplicable && !currentFolder.isInheritStatusUpdated) {
            this.onCheckInheritStatus(currentFolder, CFindex)
          }
        });
      } else {
        if (resData.message != "Request canceled by user.") {
          if (resData.message.indexOf("The SSL connection could not be established") >= 0) {
            this.getTeamChannels();
          } else {
            this.setState({ isChannelsLoader: false, channelStatusMessage: "Try again." })
          }
        }
      }
    }).catch((error: any) => {
      this.setState({ isChannelsLoader: false, channelStatusMessage: "Try again." })
    })
  }

  private onCheckInheritStatus(folder: any, folderIndex: number) {
    MSGraphService.checkInheritStatus({ teamId: this.selectedTeam.id, channelId: folder.id, membershipType: folder.membershipType, driveInfo: folder.driveInfo, folderInfo: folder.spFolderInfo }).then((resData: any) => {
      if (resData.status == "success") {
        this.state.teamContents[folderIndex].isInheritStatusLoader = false;
        this.state.teamContents[folderIndex].isInherit = resData.data;
        this.setState({ teamContents: this.state.teamContents })
      } else {
        if (folder.inheritStatusCheckCount < 5) {
          folder.inheritStatusCheckCount++;
          this.onCheckInheritStatus(folder, folderIndex);
        }
      }
    }).catch((error: any) => {
      if (folder.inheritStatusCheckCount < 5) {
        folder.inheritStatusCheckCount++;
        this.onCheckInheritStatus(folder, folderIndex);
      }
    })
  }

  private onSelectFolder(folder: any, index: number): void {
    if (folder.childFolderCount == 0) return;
    this.selectedChannel = folder;
    this.state.teamContents[index].isOpened = (this.state.teamContents[index].isOpened == undefined) ? true : !this.state.teamContents[index].isOpened;
    if (this.state.teamContents[index].contents != undefined && this.state.teamContents[index].contents.length > 0) {
      this.setState({ teamContents: this.state.teamContents })
      return;
    }
    this.state.teamContents[index].isLoading = true
    this.setState({ teamContents: this.state.teamContents })
    MSGraphService.getTeamFolderContents({ teamId: this.selectedTeam.id, driveInfo: folder.driveInfo, spFolderInfo: folder.spFolderInfo }).then((resData) => {
      this.state.teamContents[index].isLoading = false;
      if (resData.status === "success") {
        this.state.teamContents[index].contents = [];
        resData.data.forEach((currentFolder: any, CFindex: number) => {
          currentFolder.isInheritStatusLoader = false;
          this.state.teamContents[index].contents.push(currentFolder);
          this.setState({ teamContents: this.state.teamContents })
          if (currentFolder.isInheritApplicable && !currentFolder.isInheritStatusUpdated) {
            this.onCheckInheritStatus(currentFolder, CFindex)
          }
        });
        //this.state.teamContents[index].contents = resData.data;
        //this.setState({ teamContents: this.state.teamContents })
      } else {
        this.setState({ teamContents: this.state.teamContents })
      }
    }).catch((error) => {
      this.state.teamContents[index].isLoading = false;
      this.setState({ teamContents: this.state.teamContents })
      // this.setState({ isSelectedUserFoldersLoader: false })
    })
  }

  private onClickInhert(selectedCheckBox: any, folder: any, index: number) {
    let correntSelFolder = JSON.parse(JSON.stringify(folder));
    correntSelFolder.index = index;
    this.setState({ selectedFolderInfo: correntSelFolder });
    this.onShowInhertConfirmation(true, folder.isInherit);
  }

  private onShowInhertConfirmation(status: boolean, isInherit: any = null) {
    if (isInherit == null) {
      this.setState({ showInhertConfirmation: status, inhertConfirmationMessage: "" })
    } else {
      this.setState({ showInhertConfirmation: status, inhertConfirmationMessage: (isInherit) ? "Are you sure to break the parent inheritance?" : "You are about to inherit permissions from the parent folder or document library. Any custom permissions will be lost." })
    }
  }

  private onUpdateInhertStatus() {
    this.setState({ isInhertStatusUpdateProcessing: true });
    this.onShowInhertConfirmation(false);
    MSGraphService.updateInheritanceStatus({ inheritance: !this.state.selectedFolderInfo.isInherit, folderInfo: this.state.selectedFolderInfo.spFolderInfo }).then((resInsStat: any) => {
      if (resInsStat.status == "success") {
        this.state.teamContents[this.state.selectedFolderInfo.index].isInherit = !this.state.selectedFolderInfo.isInherit;
        this.setState({ teamContents: this.state.teamContents, isInhertStatusUpdateProcessing: false })
        toast.success("Inherit Success", {
          autoClose: 1000,
          hideProgressBar: true
        })
        this.state.teamContents[this.state.selectedFolderInfo.index].isMembersLoading = true;
        this.state.teamContents[this.state.selectedFolderInfo.index].members = [];
        this.setState({ teamContents: this.state.teamContents })
        MSGraphService.getAccessedList({ folderInfo: this.state.selectedFolderInfo.driveInfo }).then((resDataAccessList: any) => {
          if (resDataAccessList.status == "success") {
            this.state.teamContents[this.state.selectedFolderInfo.index].isMembersLoading = false;
            this.state.teamContents[this.state.selectedFolderInfo.index].members = resDataAccessList.data;
            this.setState({ teamContents: this.state.teamContents, selectedFolderInfo: null })
          } else {
            this.state.teamContents[this.state.selectedFolderInfo.index].accessListStatusMessage = "Try again";
            this.state.teamContents[this.state.selectedFolderInfo.index].isMembersLoading = false;
            this.setState({ teamContents: this.state.teamContents })
          }
        }).catch((errorAccessList: any) => {
          this.state.teamContents[this.state.selectedFolderInfo.index].accessListStatusMessage = "Try again";
          this.state.teamContents[this.state.selectedFolderInfo.index].isMembersLoading = false;
          this.setState({ teamContents: this.state.teamContents })
        })
      } else {
        toast.error("Inherit Failed", {
          autoClose: 1000,
          hideProgressBar: true
        })
        this.setState({ isInhertStatusUpdateProcessing: false });
      }
    }).catch((errorInsStat) => {
      toast.error("Inherit Failed", {
        autoClose: 1000,
        hideProgressBar: true
      })
      this.setState({ isInhertStatusUpdateProcessing: false });
    })
  }

  private onShowRemoveAccessConfirmation(status: boolean, folder: any = null, userIndex: any = null) {
    if (folder == null) {
      if (!status) this.setState({ showRemoveAccessConfirmation: status })
    } else {
      if (folder.isInheritApplicable && !folder.isInherit) this.setState({ selectedFolderInfo: folder, showRemoveAccessConfirmation: status, selectedAccessUserIndex: userIndex, removeAccessConfirmationMessage: (folder.members[userIndex].type == 'group') ? "Are you remove " + folder.members[userIndex].displayName + " group form this list?" : "Are you remove " + folder.members[userIndex].displayName + " form this list?" })
    }
  }

  private onRemoveAccess() {
    this.setState({ isProcessingRemoveAccess: true, showRemoveAccessConfirmation: false })
    MSGraphService.removeAccess({ driveInfo: this.state.selectedFolderInfo.driveInfo, folderInfo: this.state.selectedFolderInfo.spFolderInfo, userInfo: JSON.stringify(this.state.selectedFolderInfo.members[this.state.selectedAccessUserIndex]) }).then((resRemoveAccess: any) => {
      if (resRemoveAccess.status == "success") {
        if (this.state.selectedFolderInfo.members[this.state.selectedAccessUserIndex].type == 'group') {
          toast.success("Group access removed", {
            autoClose: 1000,
            hideProgressBar: true
          })
        } else {
          toast.success("Uesr access removed", {
            autoClose: 1000,
            hideProgressBar: true
          })
        }
        this.state.selectedFolderInfo.members.splice(this.state.selectedAccessUserIndex, 1)
        this.setState({ isProcessingRemoveAccess: false, selectedFolderInfo: this.state.selectedFolderInfo })
      } else {
        this.setState({ isProcessingRemoveAccess: false })
        toast.error("Try again.", {
          autoClose: 1000,
          hideProgressBar: true
        })
      }
    }).catch((errorRemoveAccess: any) => {
      this.setState({ isProcessingRemoveAccess: false })
      toast.error("Try again.", {
        autoClose: 1000,
        hideProgressBar: true
      })
    })
  }

  private onSelectGroupOrUser(data: any, folderIndex: number) {
    console.log(data);
    this.setState({ isProcessingAddAccess: true, showRemoveAccessConfirmation: false })
    if (data.accessType == 'group') {
      let members: any = this.state.teamContents[folderIndex].members;
      let groupExist = members.find((group: any) => (group.spId == data.groupId && group.type == "group"));
      if (groupExist != undefined && groupExist != null) {
        toast.warning("Group already exist", {
          autoClose: 1000,
          hideProgressBar: true
        })
        this.setState({ isProcessingAddAccess: false })
        return;
      }
    } else {
      let members: any = this.state.teamContents[folderIndex].members;
      let memberExist = members.find((member: any) => (member.email == data.email && member.type == "member"));
      if (memberExist != undefined && memberExist != null) {
        toast.warning("User already exist", {
          autoClose: 1000,
          hideProgressBar: true
        })
        this.setState({ isProcessingAddAccess: false })
        return;
      }
    }
    MSGraphService.updateAccess(data).then((resUpdateAccess: any) => {
      console.log(resUpdateAccess);
      if (resUpdateAccess.status == "success") {
        toast.success((data.accessType == 'group') ? "Group access added" : "User access added", {
          autoClose: 1000,
          hideProgressBar: true
        })
        this.state.teamContents[folderIndex].isMembersLoading = true;
        this.setState({ teamContents: this.state.teamContents })
        MSGraphService.getAccessedList({ folderInfo: this.state.teamContents[folderIndex].driveInfo }).then((resDataAccessList: any) => {
          if (resDataAccessList.status == "success") {
            this.state.teamContents[folderIndex].isMembersLoading = false;
            this.state.teamContents[folderIndex].members = resDataAccessList.data;
            this.setState({ teamContents: this.state.teamContents })
          } else {
            this.state.teamContents[folderIndex].accessListStatusMessage = "Try again";
            this.state.teamContents[folderIndex].isMembersLoading = false;
            this.setState({ teamContents: this.state.teamContents })
          }
        }).catch((errorAccessList: any) => {
          this.state.teamContents[folderIndex].accessListStatusMessage = "Try again";
          this.state.teamContents[folderIndex].isMembersLoading = false;
          this.setState({ teamContents: this.state.teamContents })
        })
        this.setState({ isProcessingAddAccess: false, selectedFolderInfo: this.state.selectedFolderInfo })
      } else {
        this.setState({ isProcessingAddAccess: false })
        toast.error("Try again.", {
          autoClose: 1000,
          hideProgressBar: true
        })
      }
    }).catch((errorRemoveAccess: any) => {
      this.setState({ isProcessingAddAccess: false })
      toast.error("Try again.", {
        autoClose: 1000,
        hideProgressBar: true
      })
    })
  }

  public render(): React.ReactElement<any> {
    return <>
      <ToastContainer theme="colored" />
      {(this.state.isInhertStatusUpdateProcessing || this.state.isProcessingRemoveAccess || this.state.isProcessingAddAccess) ? <PopupLoader /> : ''}
      <div className="folder-container">
        {/* {this.state.loading && <LoadingAnimation />} */}
        <div className="tree-holder">
          <div className="treeview-box">
            {/* <div className="button-container">
              <button className="fe-btn black">Cancel</button>
              <button className="fe-btn blue">Save</button>
            </div> */}
            <div className="t-row-head">
              <div className="t-col">Folder</div>
              <div className="t-col">Inherit From Parent Folder</div>
              <div className="t-col">Groups / Members</div>
            </div>
            <div className="main-list">
              {(this.state.isChannelsLoader) ? <><div className='loader-box'><DataLoader /> </div></> : ''}
              {this.state.teamContents.length > 0 ?
                this.state.teamContents.map((folder: any, folderIndex: number) => {
                  return (<>
                    <div className="t-row" key={"folder_" + folderIndex}>
                      <div className="t-col">
                        <div className="title-box" onClick={() => this.onSelectFolder(folder, folderIndex)}>
                          <div className={(folder.isOpened) ? 'arrow open' : 'arrow'}>
                            {(folder.childFolderCount > 0) ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path></svg> : <div className='arrow-dummy'></div>}
                          </div>
                          <div className="icon">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                              viewBox="0 0 100 80"    ><g>
                                <path fill="#F49D19" d="M88.6,10.8H44.8l-9.7-9.7H10.7C5.4,1.1,1,5.5,1,10.8v19.5h97.4v-9.7C98.4,15.2,94,10.8,88.6,10.8z" />
                                <path fill="#FDC829" d="M88.6,10.8H10.7c-5.4,0-9.7,4.4-9.7,9.7v48.7c0,5.4,4.4,9.7,9.7,9.7h77.9c5.4,0,9.7-4.4,9.7-9.7V20.6
      C98.4,15.2,94,10.8,88.6,10.8z"/></g>
                            </svg>
                          </div>
                          <div className={(folder.isContainNonMembers) ? "title-nonuser" : "title"} > {folder.displayName}</div>
                        </div>
                      </div>
                      <div className="t-col">
                        <span className='data-title'>Inherit From Parent Folder</span>
                        <div className="checkbox">
                          {(folder.isInheritStatusLoader) ? <div className='loader-box inherit-loader'><DataLoader /></div> : (folder.isInheritApplicable) ? <input type='checkbox' onChange={(event) => { this.onClickInhert(event.currentTarget, folder, folderIndex) }} checked={folder.isInherit} /> : ''}
                        </div>
                      </div>
                      <div className="t-col">
                        <div className="members-list-container">
                          <GroupUserPicker team={this.state.selectedTeam} folder={folder} onSelect={(data: any) => this.onSelectGroupOrUser(data, folderIndex)} disabled={(!folder.isInheritApplicable || folder.isInherit) ? true : false} />
                          <div className="members-list">
                            {(folder.isMembersLoading) ? <div className='loader-box'><DataLoader /></div> :
                              folder.members.map((member: any, memberIndex: number) => {
                                return <div className="members-item" key={folderIndex + "_" + memberIndex}>
                                  <div className='icon'>
                                    {(member.type == "group") ? <svg className='group' xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M38-160v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q62 0 120 14t131 46q32 14 50.5 42.5T678-254v94H38Zm700 0v-94q0-63-32-103.5T622-423q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519-488q24-25 36.5-61.5T568-631q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108Z" /></svg> : <svg className='user' xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-160v-94q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5T731-360q31 14 50 41t19 65v94H160Z" /></svg>}
                                  </div>
                                  <div className="member-name">{member.displayName}</div>
                                  <div className={(!folder.isInheritApplicable || folder.isInherit) ? "member-remove disable" : "member-remove"} onClick={() => this.onShowRemoveAccessConfirmation(true, folder, memberIndex)}>
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g id="Menu / Close_MD">
                                        <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {(folder.contents != undefined && folder.contents.length > 0 && (folder.isOpened != undefined && folder.isOpened)) ? <TreeViewTable data={folder.contents} team={this.selectedTeam} channel={this.selectedChannel} user={this.state.selectedUser} parentLevel={1} /> : ''}
                    {(folder.isLoading != undefined && folder.isLoading) ? <div className='loader-box'><DataLoader /></div> : ''}
                  </>)
                }) : (this.state.channelStatusMessage != "") ? <><div className='retry'><span>{this.state.channelStatusMessage}</span> <span onClick={() => this.getTeamChannels()}><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-160q-133 0-226.5-93.5T160-480q0-133 93.5-226.5T480-800q85 0 149 34.5T740-671v-129h60v254H546v-60h168q-38-60-97-97t-137-37q-109 0-184.5 75.5T220-480q0 109 75.5 184.5T480-220q83 0 152-47.5T728-393h62q-29 105-115 169t-195 64Z" /></svg></span></div></> : (!this.state.isChannelsLoader) ? "No Contents" : ""}
            </div>
          </div>
          {/* <TreeView
                      data={this.state.newData ?? []}
                      selMultipleGroups={this.state.selMultipleGroups}
                  /> */}
        </div>
      </div>
      {/* CONFIRMATION FOR BREAK AND LINK PARENT ACCESS (Inheritance) */}
      <Modal show={this.state.showInhertConfirmation} onHide={() => this.onShowInhertConfirmation(false)} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>{this.state.inhertConfirmationMessage}</div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-container">
            <button className="fe-btn black" onClick={() => this.onShowInhertConfirmation(false)}>No</button>
            <button className="fe-btn blue" onClick={() => this.onUpdateInhertStatus()}>Yes</button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.showRemoveAccessConfirmation} onHide={() => this.onShowRemoveAccessConfirmation(false)} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>Are you sure to remove </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-container">
            <button className="fe-btn black" onClick={() => this.onShowRemoveAccessConfirmation(false)}>No</button>
            <button className="fe-btn blue" onClick={() => this.onRemoveAccess()}>Yes</button>
          </div>
        </Modal.Footer>
      </Modal>

    </>

  }
}
