import React from "react";
import "./PageHeader.scss";
import { Global } from "../../services/Global";
export default class PageHeader extends React.Component<any, any> {
  private teamEmitterStatus: any = null;
  public constructor(props: any, state: any) {
    super(props);
    this.state = {
      selectedTeam: null,
    };
  }

  componentDidMount(): void {
    if (Global.getSelectedTeam() !== null) {
      this.setState({ selectedTeam: Global.getSelectedTeam() });
    }
    this.teamEmitterStatus = Global.onTeamSelected.subscribe((data: any) => {
      this.setState({ selectedTeam: data });
    });
  }

  componentWillUnmount(): void {
    Global.onTeamSelected.unSubscribe(this.teamEmitterStatus);
  }

  public render(): React.ReactElement<any> {
    return (
      <>
        <div className="fme-header">
          <div className="fm-container">
            <h1 className="page-header">
              {this.state.selectedTeam !== null
                ? String(this.state.selectedTeam?.displayName)
                : "Microsoft Teams Extranet Add-On for Legal"}
            </h1>
            <h2 className="sub-header">Administrative Portal</h2>
          </div>
        </div>
      </>
    );
  }
}
