import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

export interface IBarChartProps {
  title: string;
  labels: Array<any>;
  chartData: Array<number>;
  borderColor: string;
  backGroundColor: string;
  legend: string;
}

export default class BarChart extends React.Component<IBarChartProps, any> {
  public render() {
    const userOptions = {
      indexAxis: "y" as const,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: "right" as const,
        },
        title: {
          display: true,
          text: this.props.title,
        },
      },
    };

    const BAR_CHART_DATA = {
      labels: this.props.labels,
      datasets: [
        {
          label: this.props.legend,
          data: this.props.chartData,
          borderColor: this.props.borderColor,
          backgroundColor: this.props.backGroundColor,
        },
      ],
    };
    return <Bar options={userOptions} data={BAR_CHART_DATA} />;
  }
}
